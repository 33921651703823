import { useEffect, useState } from 'react';

export const useRBAPrice = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=zetachain&vs_currencies=usd');
        const responseData = await response.json();

        setData(responseData);
      } catch (error) {
        console.error('Unable to fetch data:', error);
      }
    };

    fetchData();
  }, [setData]);

  return data;
};
