
    import * as React from "react";
    const SVGComponent = (props) => (
      <svg
      width={18}
      height={18}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512.006 512.006"
        style={{
          enableBackground: "new 0 0 512.006 512.006",
        }}
        xmlSpace="preserve"
        {...props}
      >
        <path
          style={{
            fill: "#d83249",
          }}
          d="m23.151 149.616 115.072 115.072c7.901 7.901 20.713 7.901 28.614 0s7.901-20.713 0-28.614l-44.568-44.568h316.313v-.001c31.036 0 56.198-25.16 56.198-56.198 0-31.036-25.16-56.198-56.198-56.198H122.269l44.568-44.568c3.951-3.951 5.927-9.129 5.927-14.308s-1.976-10.357-5.927-14.308c-7.901-7.901-20.713-7.901-28.614 0L23.151 121.002c-7.901 7.901-7.901 20.713 0 28.614z"
        />
        <path
          style={{
            fill: "#ff4f59",
          }}
          d="m23.151 149.616 115.072 115.072c7.901 7.901 20.713 7.901 28.614 0s7.901-20.713 0-28.614l-44.568-44.568h139.247V79.111H122.269l44.568-44.568c3.951-3.951 5.927-9.129 5.927-14.308s-1.976-10.357-5.927-14.308c-7.901-7.901-20.713-7.901-28.614 0L23.151 121.002c-7.901 7.901-7.901 20.713 0 28.614z"
        />
        <path
          style={{
            fill: "#cfcdcf",
          }}
          d="M488.855 362.39 373.783 247.318c-7.901-7.901-20.713-7.901-28.614 0s-7.901 20.713 0 28.614l44.567 44.568H73.423v.001c-31.036 0-56.198 25.16-56.198 56.198 0 31.036 25.16 56.198 56.198 56.198h316.314l-44.568 44.568a20.17 20.17 0 0 0-5.927 14.308 20.17 20.17 0 0 0 5.927 14.308c7.901 7.901 20.713 7.901 28.614 0l115.072-115.072c7.902-7.906 7.902-20.718 0-28.619z"
        />
        <path
          style={{
            fill: "#b1b0bc",
          }}
          d="M488.855 362.39 373.783 247.318c-7.901-7.901-20.713-7.901-28.614 0s-7.901 20.713 0 28.614l44.567 44.568H261.518v112.395h128.218l-44.568 44.568a20.17 20.17 0 0 0-5.927 14.308 20.17 20.17 0 0 0 5.927 14.308c7.901 7.901 20.713 7.901 28.614 0l115.072-115.072c7.903-7.904 7.903-20.716.001-28.617z"
        />
      </svg>
    );
    export default SVGComponent;
