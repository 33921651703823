import { BigNumber } from "ethers";
import React from "react";
import { useEffect, useState } from "react";
import getSaleInfo from "utils/getSaleInfo";
import { formatBigToNum } from "utils/numberFormat";
import axios from "axios";
import { BACKEND_URL } from "config/constants/LaunchpadAddress";

export default function PercentFilled({
  address,
  setFilled = () => {},
  item,
  showModal,
  isFinished,
  isCancelled,
  saleType,
  sale,
}) {
  const [filled_percent, setFilledPercent] = useState("0");
  const [saleInfo, setSaleInfo] = useState(null);
  const [priceInBNB, setPriceInBNB] = useState(null);
  useEffect(() => {
    if (!sale) return;
    const result = getSaleInfo(address, saleType, sale.currency.symbol).then(
      (res) => {
        // console.log(res, sale, "saleingooo");
        setSaleInfo(res);
      }
    );
  }, [showModal, sale]);
  // console.log(sale)

  async function getPrice() {
    if (!saleInfo) return;
    let res;
    const coinSymbol = sale.currency.symbol;
    if (sale.saleType === "public" || sale.saleType === "publicvesting"  || sale.saleType === "private" || sale.saleType === "fairlaunch" || sale.currency.symbol === coinSymbol) {
      res = await saleInfo.totalBNBRaised;
    } else {
      res = await saleInfo.totalERC20Raised;
    }
    console.log('saleInfo:', saleInfo.totalBNBRaised)
    console.log(res, "in get price");
    const temp = BigNumber.from(res);
    console.log(formatBigToNum(temp.toString(), 0, 1),"in get price")
    setPriceInBNB(temp);
  }

  async function store() {
    console.log("store");
    if (!item) return;
    if (item.visible === false) return;
    try {
      const res = await axios.put(`${BACKEND_URL}/api/sale/${item._id}`, {
        visible: false,
      });
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getPrice();
  }, [saleInfo]);

  useEffect(() => {
    // if (priceInBNB === null) return;
    const getFilledPercent = async () => {
      try {
        const newPercent = sale.filledPercent;
        setFilledPercent(newPercent ? newPercent : "0");
        setFilled(newPercent ? newPercent : "0");

        //make request to server if newPercent is 100
        if ((sale.saleType === "public" || sale.saleType === "publicvesting"  || sale.saleType === "private") && parseInt(newPercent) === 100 || isFinished || isCancelled) {
          store();
        } else if (sale.saleType === "fairlaunch" && parseInt(newPercent) === 10000 || isCancelled) {
          store();
        }
      } catch (err) {
        console.log(err);
      }
    };

    getFilledPercent();
  }, [priceInBNB]);

  return (
    <div className="w-full bg-[#F5F1EB] dark:bg-dark-3 rounded-[5px] h-[18px] mt-[6px]">
      {(sale.saleType === "public" || sale.saleType === "publicvesting"  || sale.saleType === "private") && filled_percent !== "0" && (
        <div
          className={`h-18px filled-green rounded-[5px] pr-2 flex justify-end items-center text-xs text-black`}
          style={{
            width: `${filled_percent}%`,
          }}
        >
          {/* here too where filled percentage */}
          {filled_percent}%
        </div>
      )}
      {sale.saleType === "fairlaunch" && filled_percent !== "0" && (
        <div
          className={`h-18px filled rounded-[5px] pr-2 flex justify-end items-center text-xs text-black ${
            parseInt(filled_percent) <= 100 ? 'filled-green' :
            parseInt(filled_percent) <= 200 ? 'filled-orange' :
            parseInt(filled_percent) <= 300 ? 'filled-red' :
            parseInt(filled_percent) <= 400 ? 'filled-fire' : 'filled-fullfire'
          }`}
          style={{
            width: `${Math.min(parseInt(filled_percent), 100)}%`, // Batasi lebar maksimum ke 100%
          }}
        >
          {filled_percent}%
        </div>
      )}

    </div>
  );
}
