import BaseLayout from "../../components/BaseLayout/BaseLayout";

export default function WhitePaper() {
  return (
    <BaseLayout>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <iframe title="TowerSwap Docs" src="https://drive.google.com/file/d/10FayK8N1PMYerrf-yRGM1kRDTLIL8Esv/preview" width="640" height="480"></iframe>
      </div>
    </BaseLayout>
  );
}
