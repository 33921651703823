import React, { useState, useEffect } from "react";
import BackArrowSVG from "../../../svgs/back_arrow";
import Web3 from 'web3';
import PreviewHeader from "../../Common/PreviewHeader";
import HeadingTags from "../../TokenLocker/Subcomponents/HeadingTags";
import CalendarField from "./Subcomponents/CalendarField";
import CurrencyOptions from "./Subcomponents/CurrencyOption";
import DexOptions from "./Subcomponents/DexOption";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Input from "./Subcomponents/Input";
import PresaleStandard from "./Subcomponents/PresaleStandard";
import PresalePrivate from "./Subcomponents/PresalePrivate";
import { useModal } from "react-simple-modal-provider";
import { ethers } from "ethers";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import ERCAbi from "../../../config/abi/ERC20.json";
import { useDefaultChainId } from "config/useDefaultChainId";
import { approveTokens } from "utils/deploySale";
import {
  Public_FACTORY,
  FairLaunchErc_FACTORY,
  FairLaunch_FACTORY,
  PrivateErc_FACTORYADRESS,
  Private_FACTORY,
  PublicErc_FACTORYADRESS,
  PublicVesting,
  USDT_ADDRESS,
  ROUTER_ADDRESS,
  TOWER_TOKEN,
  USDC_ADDRESS,
  USDT_ADDRESS_BSC,
  USDC_ADDRESS_BSC,
} from "config/constants/LaunchpadAddress";

const currencies1 = [
  {
    id: 1,
    name: "SHARDEUM",
    symbol: "SHM",
    icon: "/images/cards/shm.svg",
    address: "",
  },
  /*{
    id: 2,
    name: "Tower Token",
    symbol: "TWS",
    icon: "/images/cards/tws.svg",
    address: TOWER_TOKEN,
  },
  {
    id: 3,
    name: "USD Coin",
    symbol: "USDC",
    icon: "/images/cards/gusd.svg",
    address: USDC_ADDRESS,
  },
  {
    id: 4,
    name: "Tether",
    symbol: "USDT",
    icon: "/images/cards/usdt.svg",
    address: USDT_ADDRESS,
  }, */
];
const currencies2 = [
  {
    id: 1,
    name: "Binance",
    symbol: "BNB",
    icon: "/images/cards/bnb.svg",
    address: "",
  },
  /* {
    id: 2,
    name: "Tower Token",
    symbol: "TWS",
    icon: "/images/cards/tws.svg",
    address: TOWER_TOKEN,
  },
  {
    id: 3,
    name: "USD Coin",
    symbol: "USDC",
    icon: "/images/cards/gusd.svg",
    address: USDC_ADDRESS,
  },
  {
    id: 4,
    name: "Tether",
    symbol: "USDT",
    icon: "/images/cards/usdt.svg",
    address: USDT_ADDRESS,
  }, */
];
const currencies3 = [
  {
    id: 1,
    name: "ZetaCoin",
    symbol: "ZETA",
    icon: "/images/cards/zeta.svg",
    address: "",
  },
  /*{
    id: 2,
    name: "Tower Token",
    symbol: "TWS",
    icon: "/images/cards/tws.svg",
    address: TOWER_TOKEN,
  },
  {
    id: 3,
    name: "USD Coin",
    symbol: "USDC",
    icon: "/images/cards/gusd.svg",
    address: USDC_ADDRESS,
  },
  {
    id: 4,
    name: "Tether",
    symbol: "USDT",
    icon: "/images/cards/usdt.svg",
    address: USDT_ADDRESS,
  }, */
];

const currencies4 = [
  {
    id: 1,
    name: "BASE ETH",
    symbol: "ETH",
    icon: "/images/cards/Base.png",
    address: "",
  },
  /*{
    id: 2,
    name: "Tower Token",
    symbol: "TWS",
    icon: "/images/cards/tws.svg",
    address: TOWER_TOKEN,
  },
  {
    id: 3,
    name: "USD Coin",
    symbol: "USDC",
    icon: "/images/cards/gusd.svg",
    address: USDC_ADDRESS,
  },
  {
    id: 4,
    name: "Tether",
    symbol: "USDT",
    icon: "/images/cards/usdt.svg",
    address: USDT_ADDRESS,
  },*/
];

const dexes = [
  { id: 1, name: "TowerSwap", icon: "/images/cards/tws.svg", address: "0x37b1fC2b5062526bf6b177Fdcd5992122b138c4d" },
  // { id: 2, name: "Uniswap", icon: "/images/cards/pancake.svg", address: "0x37b1fC2b5062526bf6b177Fdcd5992122b138c4d" },
];

const dexes2 = [
  { id: 1, name: "TowerSwap", icon: "/images/cards/tws.svg", address: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1" },
  // { id: 2, name: "Pancakeswap", icon: "/images/cards/pancake.svg", address: "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3" },
];

const dexes3 = [
  { id: 1, name: "TowerSwap", icon: "/images/cards/tws.svg", address: "0xeeF9560CE1f1358011E41974dbE68F8a0Bd86E50" },
  // { id: 2, name: "Pancakeswap", icon: "/images/cards/pancake.svg", address: "0xeeF9560CE1f1358011E41974dbE68F8a0Bd86E50" },
];

const dexes4 = [
  { id: 1, name: "TowerSwap", icon: "/images/cards/tws.svg", address: "0x004Ccd46a242F279aD0aeF229d3703Fb0CcCD89a" },
  // { id: 2, name: "Uniswap", icon: "/images/cards/pancake.svg", address: "0xfA701a1dc06066029FF58EC6d6A5f944ba0e467a" },
];

export default function Presale({ setActive, saleType, setSaleObject, token }) {
  const chainId = useDefaultChainId();
  const [currencySelected, setCurrencySelected] = useState(1);
  const [currencies, setCurrencies] = useState(currencies1);
  const [tempfixed, setTempFixed] = useState(true);
  const [dex, setDex] = useState(2);
  const [selectedDex, setSelectedDex] = useState(1);
  const [presalePrice, setPresalePrice] = useState();
  const [fairAmount, setFairAmount] = useState();
  const [softCap, setSoftCap] = useState();
  const [hardCap, setHardCap] = useState();
  const [minAllocation, setMinAllocation] = useState();
  const [maxAllocation, setMaxAllocation] = useState();
  const [whiteisting, setWhiteisting] = useState(false);
  const [listing, setListing] = useState();
  const [amountLiquidity, setAmountLiquidity] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [tgeRelaseDate, setTgeRelaseDate] = useState();
  const [releaseDate1, setReleaseDate1] = useState();
  const [releaseDate2, setReleaseDate2] = useState();
  const [releaseDate3, setReleaseDate3] = useState();
  const [releaseDate4, setReleaseDate4] = useState();
  const [whitelistEndDate, setWhitelistEndDate] = useState();
  const [firstRelease, setFirstRelease] = useState();
  const [vestingPeriod, setVestingPeriod] = useState();
  const [vestingRelease, setVestingRelease] = useState();
  const [unsoldToken, setUnsoldTokens] = useState("Burn");
  const [requiredToken, setRequiredToken] = useState("0");
  const [connected, setConnected] = useState(false);
  const [lockup, setLockup] = useState();
  const { account, library } = useEthers();
  const [enoughBalance, setEnoughBalance] = useState(false);
  const [whiteListedAddresses, setWhiteListedAddresses] = useState([""]);
  const [percent1, setPercent1] = useState(0);
  const [percent2, setPercent2] = useState(0);
  const [percent3, setPercent3] = useState(0);
  const [percent4, setPercent4] = useState(0);

  const { open: openLoadingModal, close: closeLoadingModal } =
    useModal("LoadingModal");
  const { open: openModal } = useModal("ConnectionModal");

  useEffect(() => {
    if (chainId === 8082) {
      setCurrencies(currencies1);
    } else if (chainId === 56) {
      setCurrencies(currencies2);
    } else if (chainId === 7000) {
      setCurrencies(currencies3);
    } else if (chainId === 8453) {
      setCurrencies(currencies4);
    }

  }, [chainId]);

  useEffect(() => {
      if (chainId === 8082) {
          setDex(dexes);
      } else if (chainId === 56) {
          setDex(dexes2);
      } else if (chainId === 7000) {
          setDex(dexes3);
      } else if (chainId === 8453) {
          setDex(dexes4);
      }
  }, [chainId]);


  const handleAddressChange = (newValue) => {
    const addressesArray = newValue.split(',');
    const updatedAddresses = addressesArray.map((address) => address.trim().toLowerCase());
    setWhiteListedAddresses(updatedAddresses);
  };

  const handleSubmit = async () => {
    try {
    //if start date is less than current date and time, and also if end date is less than start date
    const now = moment();
    if (account === undefined) {
      toast.error("Please connect your wallet");
    }

    console.log("Before date validations");
    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);

    if (startDate < now.unix()) {
      toast.error("Start date should be greater than current date and time");
      return;
    }
    if (endDate < startDate) {
      toast.error("End date should be greater than start date");
      return;
    }
    if (whiteisting) {
      if (whiteListedAddresses[0] === "") {
        toast.error("Whitelist address can't be empty");
        return;
      }
    }
    let total = parseFloat(percent1)+parseFloat(percent2)+parseFloat(percent3)+parseFloat(percent4);
    if(saleType==="private"&&total!==100){
      toast.error("Percentages should add up to 100");
      return;
    }
    if (!enoughBalance) {
      toast.error("Insufficient Balance!");
      return;
    }
    if (saleType==="public"&&(amountLiquidity < 51 || amountLiquidity === undefined)) {
      toast.error("Liquidity should be greater than 50%");
      return;
    }
    //listing cant be less than presale rate
    if (parseFloat(listing) > parseFloat(presalePrice)) {
      toast.error("Listing rate can't be more than presale rate");
      return;
    }

    let res = false;
    openLoadingModal();
    if (saleType === "public") {
      if (
        lockup === undefined ||
        maxAllocation === undefined ||
        minAllocation === undefined ||
        hardCap === undefined ||
        softCap === undefined ||
        presalePrice === undefined ||
        endDate === undefined ||
        startDate === undefined ||
        amountLiquidity === undefined ||
        listing === undefined
      ) {
        toast.error("Please fill all the fields");
        closeLoadingModal()
        return;
      }
      if (currencySelected === 1) {
        if (chainId === chainId) {
          console.log('currencySelected:', currencySelected);
          const publicFactory = Public_FACTORY[chainId];
          res = await approveTokens(library, token, publicFactory);
        }
      } else {
        const publicErcFactory = PublicErc_FACTORYADRESS[chainId];
        res = await approveTokens(library, token, publicErcFactory);
      }
    } else if (saleType === "publicvesting" ) {
      if (currencySelected === 1) {
        const publicVestFactory = PublicVesting[chainId];
        res = await approveTokens(library, token, publicVestFactory);
      }
    } else if (saleType === "private") {
      if (currencySelected === 1) {
        const privateFactory = Private_FACTORY[chainId];
        res = await approveTokens(library, token, privateFactory);
        console.log('chainId:', chainId);
        console.log('privateFactory:', privateFactory);
        console.log('token:', token);
      } else {
        res = await approveTokens(library, token, PrivateErc_FACTORYADRESS);
      }
    } else if (saleType === "fairlaunch") {
      if (currencySelected === 1) {
        const fairFactory = FairLaunch_FACTORY[chainId];
        res = await approveTokens(library, token, fairFactory);
      } else {
        const fairErcFactory = FairLaunchErc_FACTORY[chainId];
        res = await approveTokens(library, token, fairErcFactory);
      }
    }

    if (!res) {
      closeLoadingModal();
      return;
    }

    const presaleObject = {
      currency: currencies[currencySelected - 1],
      dex: dex[selectedDex - 1],
      presalePrice: presalePrice,
      fairAmount: fairAmount,
      softCap: softCap,
      hardCap: hardCap,
      minAllocation: minAllocation,
      maxAllocation: maxAllocation,
      whiteisting: whiteisting,
      listing: listing,
      firstRelease: firstRelease,
      percent1: percent1,
      percent2: percent2,
      percent3: percent3,
      percent4: percent4,
      tgeRelaseDate: tgeRelaseDate,
      releaseDate1: releaseDate1,
      releaseDate2: releaseDate2,
      releaseDate3: releaseDate3,
      releaseDate4: releaseDate4,
      amountLiquidity: amountLiquidity,
      startDate: startDate,
      endDate: endDate,
      whitelistEndDate: whitelistEndDate,
      unsoldToken: unsoldToken,
      lockup: lockup,
      owner: account,
      isFinished: false,
      whiteListedAddresses: whiteListedAddresses,
    };

    setSaleObject(presaleObject);
    console.log(presaleObject);
    closeLoadingModal();
    setActive("Project Details");
  } catch (error) {
    console.error("Error in handleSubmit:", error);
    console.error(error.message);
    console.error(error.stack);
    closeLoadingModal();
  }
  };

  const handleCheckBalance = async () => {
    const contract = new Contract(
      token.tokenAddress,
      ERCAbi,
      library.getSigner(account)
    );
    const amountRequired = ethers.utils.parseUnits(
      requiredToken.toString(),
      ethers.BigNumber.from(token.tokenDecimals)
    );
    try {
      const balance = await contract.balanceOf(account);

      if (balance.gte(amountRequired)) {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error)
      return false;
    }
  };

  useEffect(() => {
    if (!account) {
      setConnected(false);
      openModal();
    } else {
      setConnected(true);
    }
  }, [account]);

  //use effect in which we will set required token if hardcap, softcap, listing price, amount liquidity, presale price changes
  useEffect(() => {
    if (hardCap > 0 && presalePrice > 0 && listing > 0 && (saleType === "public" || saleType === "publicvesting" )) {
      const reqTokens = hardCap * presalePrice;
      const reqTokens2 = (listing * (amountLiquidity / 100)) * hardCap;
      console.log(reqTokens, reqTokens2);
      setRequiredToken((reqTokens + reqTokens2).toFixed(2));
    }
    if (saleType === "private") {
      const reqTokens = hardCap * presalePrice;
    setRequiredToken((reqTokens).toFixed(2));
    }
    if (saleType === "fairlaunch") {
      const reqTokens = 1 * presalePrice;
      const reqTokens2 = presalePrice * (amountLiquidity / 100);
      console.log(reqTokens, reqTokens2);
    setRequiredToken((reqTokens + reqTokens2).toFixed(2));
    }
  }, [hardCap, softCap, listing, presalePrice, fairAmount]);

  useEffect(() => {
    console.log("use effect");
    checkBalance();
  }, [requiredToken, account, connected]);

  async function checkBalance() {
    console.log("check balance");
    const check = await handleCheckBalance();

    if (check) {
      setEnoughBalance(true);
    } else {
      setEnoughBalance(false);
    }
  }

  const handleTempFixed = () => {
    setWhiteisting(!whiteisting);
    setTempFixed(!tempfixed);
  };
  return (
    <>
      <div className="w-full">
        {/* sticky navbar */}
        <HeadingTags name={"Choose Currency"} required />

        {/* Currency Options */}
        <div className="grid grid-cols-2 md:grid-cols-2 gap-4 mt-4">
          {currencies.map((currency) => (
            <CurrencyOptions
              key={currency.id}
              selected={currency.id === currencySelected}
              setCurrency={setCurrencySelected}
              {...currency}
            />
          ))}
        </div>

        <PreviewHeader heading={"Presale Details"} />

        {(saleType === "public" || saleType === "publicvesting"  || saleType === "private") && (
          <>
            <Input
              heading={"Presale Price"}
              currencies={currencies}
              currencySelected={currencySelected}
              changeState={setPresalePrice}
            />
            <div className="flex items-center gap-5 mt-10">
              <div className="w-full">
                <Input
                  heading={"Soft Cap"}
                  currencies={currencies}
                  currencySelected={currencySelected}
                  changeState={setSoftCap}
                />
              </div>
              <div className="w-full">
                <Input
                  heading={"Hard Cap"}
                  currencies={currencies}
                  currencySelected={currencySelected}
                  changeState={setHardCap}
                />
              </div>
            </div>
            <div className="flex items-center gap-5 mt-10">
              <div className="w-full">
                <Input
                  heading={"Min Allocation"}
                  currencies={currencies}
                  currencySelected={currencySelected}
                  changeState={setMinAllocation}
                />
              </div>
              <div className="w-full">
                <Input
                  heading={"Max Allocation"}
                  currencies={currencies}
                  currencySelected={currencySelected}
                  changeState={setMaxAllocation}
                  tooltip={
                    "The maximum allocation for a token refers to the maximum amount of that token that can be assigned or allocated."
                  }
                />
              </div>
            </div>
          </>
        )}

        {saleType === "fairlaunch" && (
          <>
          <div className="flex items-center gap-5 mt-10">
            <div className="w-full">
            <Input
              heading={"Amount to be sold"}
              currencies={currencies}
              currencySelected={currencySelected}
              changeState={setPresalePrice}
            />
            </div>
            <div className="w-full">
              <Input
                heading={"Soft Cap"}
                currencies={currencies}
                currencySelected={currencySelected}
                changeState={setSoftCap}
              />
            </div>
          </div>
            <div className="flex items-center gap-5 mt-10">
              <div className="w-full">
                <Input
                  heading={"Min Allocation"}
                  currencies={currencies}
                  currencySelected={currencySelected}
                  changeState={setMinAllocation}
                />
              </div>
              <div className="w-full">
                <Input
                  heading={"Max Allocation"}
                  currencies={currencies}
                  currencySelected={currencySelected}
                  changeState={setMaxAllocation}
                  tooltip={
                    "The maximum allocation for a token refers to the maximum amount of that token that can be assigned or allocated."
                  }
                />
              </div>
            </div>
          </>
        )}

          <div>
            <div className="flex items-center justify-between mt-10">
              <span className="text-gray dark:text-gray-dark font-semibold">
                Enable Whitelisting
              </span>

              <label
                htmlFor="whitelist-toggle"
                className="inline-flex relative items-center cursor-pointer"
              >
                <input
                  type="checkbox"
                  value=""
                  checked={tempfixed ? false : true}
                  id="whitelist-toggle"
                  className="sr-only peer"
                  onChange={handleTempFixed}
                />
                <div className="w-10 h-5 md:w-10 md:h-5 bg-primary-green bg-opacity-[0.08] peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[3px] after:md:top-[2px] after:left-[0] after:md:left-[4px] after:bg-white after:rounded-full after:h-4 after:md:h-4 after:w-4 after:md:w-4 after:transition-all  peer-checked:after:bg-primary-green" />
              </label>
            </div>

            <div className="mt-5">
              <span className="text-gray dark:text-gray-dark font-semibold text-sm">
                Note
                <span className="font-medium">
                  &nbsp;: When turned on only users that are whitelisted will be
                  able to participate in the presale.
                </span>
              </span>
            </div>
          </div>
        {whiteisting && (
          <>
            <div className="mt-5" >
              <Input
                heading={`Whitelisted Address`}
                changeState={(newValue) =>
                  handleAddressChange(newValue)
                }
                whitelist={true}
                tooltip="Enter addresses to whitelist them for the presale"
                placeholder="0xaEa574007c8ad33c7f4f7CF4a0d0B6F704ACD59e,..."
                nothing={true}
              />
              <div className="mt-2">
                  <CalendarField
                    heading={`Whitelist ends on (UTC)`}
                    setFunction={setWhitelistEndDate}
                  />
                </div>
            </div>
          </>
        )}

        {(saleType === "public" || saleType === "publicvesting"  || saleType === "fairlaunch") && (
          <div>
            <PreviewHeader heading={"Listing Details"} />
            <div className="mt-10">
              <HeadingTags name={"Choose DEX to be listed on"} required />
            </div>
            {chainId === 8453 && (
                        <div className="flex items-center gap-5 mt-10">
                            <DexOptions
                                selected={selectedDex === 1}
                                id={1}
                                setDex={setSelectedDex}
                                name={"TowerSwap"}
                                icon={"/images/cards/tws.svg"}
                                address={"0xfA701a1dc06066029FF58EC6d6A5f944ba0e467a"}
                            />
                            <DexOptions
                                selected={selectedDex === 2}
                                id={2}
                                setDex={setSelectedDex}
                                name={"Uniswap"}
                                icon={"/images/cards/pancake.svg"}
                                address={"0x1689E7B1F10000AE47eBfE339a4f69dECd19F602"}
                            />
                        </div>
                    )}
                    {chainId === 7000 && (
                        <div className="flex items-center gap-5 mt-10">
                            <DexOptions
                                selected={selectedDex === 1}
                                id={1}
                                setDex={setSelectedDex}
                                name={"TowerSwap"}
                                icon={"/images/cards/tws.svg"}
                                address={"0xeeF9560CE1f1358011E41974dbE68F8a0Bd86E50"}
                            />
                        </div>
                    )}
                    {chainId === 56 && (
                        <div className="flex items-center gap-5 mt-10">
                            {/*<DexOptions
                                selected={selectedDex === 1}
                                id={1}
                                setDex={setSelectedDex}
                                name={"TowerSwap"}
                                icon={"/images/cards/tws.svg"}
                                address={"0xD99D1c33F9fC3444f8101754aBC46c52416550D1"}
                            />*/}
                            <DexOptions
                                selected={selectedDex === 2}
                                id={2}
                                setDex={setSelectedDex}
                                name={"PancakeSwap"}
                                icon={"/images/cards/pancake.svg"}
                                address={"0x10ED43C718714eb63d5aA57B78B54704E256024E"}
                            />
                        </div>
                    )}
                    {chainId === 8082 && (
                        <div className="flex items-center gap-5 mt-10">
                            <DexOptions
                                selected={selectedDex === 1}
                                id={1}
                                setDex={setSelectedDex}
                                name={"TowerSwap"}
                                icon={"/images/cards/tws.svg"}
                                address={"0x37b1fC2b5062526bf6b177Fdcd5992122b138c4d"}
                            />
                            <DexOptions
                                selected={selectedDex === 2}
                                id={2}
                                setDex={setSelectedDex}
                                name={"Uniswap"}
                                icon={"/images/cards/pancake.svg"}
                                address={"0x37b1fC2b5062526bf6b177Fdcd5992122b138c4d"}
                            />
                        </div>
                    )}


            <div className="flex items-center gap-5 mt-10">
              <div className="w-full">
                <div className="hidden md:block">
                  <Input
                    heading={"% for Liquidity"}
                    changeState={setAmountLiquidity}
                    tooltip={
                      "Ready availability of assets for seamless and hassle-free decentralized financial transactions and activities."
                    }
                  />
                </div>
                <div className="md:hidden">
                  <Input
                    heading={"Liquidity"}
                    changeState={setAmountLiquidity}
                  />
                </div>
              </div>
              {(saleType === "public" || saleType === "publicvesting") && (
                <div className="w-full">
                  <Input
                    heading={"Listing Price"}
                    currencies={currencies}
                    currencySelected={currencySelected}
                    changeState={setListing}
                  />
                </div>
              )}
              {saleType === "fairlaunch" && (
                <div className="w-full">
                  <Input
                    heading={"Liquidity Amount"}
                    currencies={currencies}
                    currencySelected={currencySelected}
                    changeState={setFairAmount}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        <PreviewHeader heading={"Time Details"} />

        <div className="flex flex-col md:flex-row items-center gap-5 mt-10">
          <div className="w-full md:w-1/2">
            <CalendarField heading={"Starts On (UTC)"} setFunction={setStartDate} />
          </div>
          <div className="w-full md:w-1/2">
            <CalendarField heading={"Ends On (UTC)"} setFunction={setEndDate} />
          </div>
        </div>
        {(saleType === "public" || saleType === "publicvesting"  || saleType === "fairlaunch") && (
          <div className="flex flex-col md:flex-row items-center gap-5 mt-10">
          <div className="w-full md:w-1/2">
            <Input className="text-gray" heading={'Unsold tokens'} icon1={"/images/arrows/arrow-down.svg"} icon2={"/images/arrows/arrow-up.svg"} setFunction={setUnsoldTokens} value={unsoldToken} disabled={true} />
          </div>
          <div className="w-full md:w-1/2">
          <CalendarField
            heading={'Lockup (times)'}
            setFunction={setLockup}
          />
          </div>
          </div>
        )}

        {(saleType === "private" || saleType === "publicvesting" ) && (
          <div>
            <PreviewHeader heading={"Token Vesting Details"} />
            <div className="w-full">
            <div className="mt-4 mb-4">
            <PresalePrivate
              setFirstRelease={setFirstRelease}
            />
            </div>
            <CalendarField
              heading={"First Release Time On (UTC)"}
              setFunction={setTgeRelaseDate}
            />
            </div>
            <PreviewHeader heading={"Vesting Portions"} />
            <div className="flex items-center gap-5 mt-10">
              <div className="w-full">
              <CalendarField
                heading={"Release On (UTC)"}
                setFunction={setReleaseDate1}
              />
              </div>
              <div className="w-full">
              < Input
                heading={"Portion 1 Amount"}
                changeState={setPercent1}
                tooltip={"Enter the percentage of tokens to be released in the second vesting period"}
                placeholder={"0"}
                nothing={true}
              />
              </div>
            </div>
              <div className="flex items-center gap-5 mt-10">
                <div className="w-full">
                <CalendarField
                  heading={"Release On (UTC)"}
                  setFunction={setReleaseDate2}
                />
                </div>
                <div className="w-full">
                <Input
                  heading={"Portion 2 Amount"}
                  changeState={setPercent2}
                  tooltip={"Enter the percentage of tokens to be released in the fourth vesting period"}
                  placeholder={"0"}
                  nothing={true}
                />
                </div>
              </div>
              <div className="flex items-center gap-5 mt-10">
                <div className="w-full">
                <CalendarField
                  heading={"Release On (UTC)"}
                  setFunction={setReleaseDate3}
                />
                </div>
                <div className="w-full">
                < Input
                  heading={"Portion 3 Amount"}
                  changeState={setPercent3}
                  tooltip={"Enter the percentage of tokens to be released in the second vesting period"}
                  placeholder={"0"}
                  nothing={true}
                />
                </div>
              </div>
                <div className="flex items-center gap-5 mt-10">
                  <div className="w-full">
                  <CalendarField
                    heading={"Release On (UTC)"}
                    setFunction={setReleaseDate4}
                  />
                  </div>
                  <div className="w-full">
                  <Input
                    heading={"Portion 4 Amount"}
                    changeState={setPercent4}
                    tooltip={"Enter the percentage of tokens to be released in the fourth vesting period"}
                    placeholder={"0"}
                    nothing={true}
                  />
                  </div>
                </div>

          </div>
        )}
        {(saleType === "public" || saleType === "publicvesting") && (
          <div className="flex justify-center mt-7 bg-[#E56060] bg-opacity-[0.08] py-3 rounded-[10px]">
            <img src="/images/create-sale/warning.svg" alt="warning" />
            <span className="text-[#E56060] font-medium text-sm">
              To Create public Sale{" "}
              <span className="font-bold">
                {requiredToken} {token.name}
              </span>{" "}
              is required.
            </span>
          </div>
        )}

        {saleType === "private"  && (
          <div className="flex justify-center mt-7 bg-[#E56060] bg-opacity-[0.08] py-3 rounded-[10px]">
            <img src="/images/create-sale/warning.svg" alt="warning" />
            <span className="text-[#E56060] font-medium text-sm">
              To Create private Sale{" "}
              <span className="font-bold">
                {requiredToken} {token.name}
              </span>{" "}
              is required.
            </span>
          </div>
        )}

        {saleType === "fairlaunch"  && (
          <div className="flex justify-center mt-7 bg-[#E56060] bg-opacity-[0.08] py-3 rounded-[10px]">
            <img src="/images/create-sale/warning.svg" alt="warning" />
            <span className="text-[#E56060] font-medium text-sm">
              To Create Fair Sale{" "}
              <span className="font-bold">
                {requiredToken} {token.name}
              </span>{" "}
              is required.
            </span>
          </div>
        )}

        <div className="mt-10">
          <div className="flex justify-end items-center mb-10">
            <button
              className="bg-white dark:bg-transparent mr-5 flex items-center gap-2 py-[10px] px-5"
              onClick={() => setActive("Token Info")}
            >
              <BackArrowSVG className="fill-dark-text dark:fill-light-text" />
              <span className="font-gilroy font-medium text-sm text-dark-text dark:text-light-text">
                Go Back
              </span>
            </button>

            <button
              className="bg-primary-green hover:opacity-40 disabled:bg-light-text text-white font-gilroy font-bold px-8 py-3 rounded-md"
              // disabled={address.length < 5}
              onClick={handleSubmit}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
