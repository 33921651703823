import React, { useState, useEffect } from 'react';
import SearchSVG from '../../svgs/search';
import Carousel from './Slider/Slider';
import ItemSwitch from './Switches/ItemSwitch';
import Tab from './Switches/Tab';
import ViewSwitch from './Switches/ViewSwitch';

export default function HomeLayout({
  children,
  cardFormat,
  setCardFormat,
  itemSelected,
  setItemSelected,
  tabs,
  activeTab,
  setActiveTab,
  airdrop,
  setFilteredPools,
  launchpad,
  pools,
  locker
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const [on, setOn] = useState(true);
  const [filteredPools, setFilteredPoolsLocal] = useState([]);

  const handleToggle = () => {
    setOn(!on);
  };

  useEffect(() => {
    if (!airdrop && !locker) {
      const filteredPools = pools?.filter((pool) => {
        const tokenSymbol = pool?.sale?.token?.tokenSymbol?.toLowerCase();
        const tokenName = pool?.sale?.token?.tokenName?.toLowerCase();
        const saleAddress = pool?.sale?.saleAddress?.toLowerCase();
        const query = searchQuery.toLowerCase();
        return tokenSymbol?.includes(query) || tokenName?.includes(query) || saleAddress?.includes(query);
      });
      setFilteredPools(filteredPools);
      setFilteredPoolsLocal(filteredPools);
    }
  }, [searchQuery, airdrop, locker, pools, setFilteredPools]);

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full px-6 md:px-0 md:w-10/12">
        <div className="w-full flex mt-5 md:mt-0 flex-col md:flex-row gap-y-3 md:h-11 justify-between">
          {tabs && (
            <div className="flex justify-around md:justify-start items-center p-1 bg-[#F5F1EB] dark:bg-dark-3">
              {tabs.map((tab) => (
                <Tab
                  key={tab.id}
                  tabName={tab.tabName}
                  active={tab.id === activeTab}
                  setActiveTab={setActiveTab}
                  id={tab.id}
                />
              ))}
            </div>
          )}

          {!tabs && <ViewSwitch cardFormat={cardFormat} setCardFormat={setCardFormat} />}
          {!tabs && <ItemSwitch itemSelected={itemSelected} setItemSelected={setItemSelected} />}

          <div className="hidden md:flex items-center justify-between border-2 border-white dark:border-dark-1 bg-[#F5F1EB] dark:bg-dark-3 rounded-md px-5 py-3">
            <input
              className="bg-transparent dark:text-white placeholder:text-dim-text dark:placeholder:text-dim-text-dark focus:outline-none w-60"
              placeholder="Search Sales"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <SearchSVG className="fill-dark-text dark:fill-light-text" />
          </div>
        </div>

        <div className="flex justify-between md:hidden mt-5 h-11 relative z-10">
          <div className="flex w-full items-center justify-between border-2 border-white dark:border-dark-1 bg-[#F5F1EB] dark:bg-dark-3 rounded-md py-3">
            <input
              className="pl-2 bg-transparent placeholder:text-dim-text dark:placeholder:text-dim-text-dark focus:outline-none"
              placeholder="Search Sales"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <SearchSVG className="relative -left-3 fill-dark-text dark:fill-light-text" />
          </div>
        </div>

        {/* Render filtered pools */}

        {children}
      </div>
    </div>
  );
}
