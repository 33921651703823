import * as React from "react";
const SVGComponent = (props) => (
      <svg
      width={25}
      height={25}
        viewBox="0 0 128 128"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        className="iconify iconify--noto"
        {...props}
      >
        <path
          d="M16.77 19.75c-1 0-1.7.8-1.7 1.8v.1c-1.5 25.91 3.03 59.27 24.01 83.52 12.1 14.7 23.91 18.53 24.51 18.73 0 0 .28.09.54.09s.57-.1.57-.1c.5-.2 12.48-4.02 24.49-18.72 19.91-24.21 24.01-58.82 24.01-83.52v-.1c0-1-.8-1.8-1.7-1.8-.3 0-29.11-1-46.01-15.3-.7-.6-1.7-.6-2.4 0-17.11 14.2-46.02 15.2-46.32 15.3"
          fill="#b0bec5"
        />
        <path
          d="M111.49 19.75c-.3 0-29.11-1-46.01-15.3-.4-.35-.83-.45-1.24-.45h-.11v120c.27-.01.62-.11.62-.12.6-.25 12.44-4 24.44-18.7 19.91-24.21 24.01-58.82 24.01-83.52v-.1c0-1.01-.8-1.81-1.71-1.81"
          fill="#84b0c1"
        />
        <path
          d="M26.33 28.31c-.82 0-1.02 1.02-1.02 1.74v.1c0 19.72 3.06 47.4 19 66.71 9.6 11.75 19 14.81 19.41 14.91l.41.1.41-.1c.41-.1 9.81-3.17 19.41-14.91 15.94-19.31 19-46.89 19-66.71v-.1c0-.82-.41-1.43-1.23-1.43h.1c-.2 0-23.19-.82-36.67-12.16-1.19-.98-1.94-.2-1.94-.2C49.63 27.6 26.64 28.31 26.33 28.31"
          fill="#2f7889"
        />
        <path
          d="M29.18 30.07c-.76 0-.94.96-.94 1.64v.1c0 18.57 2.83 44.65 17.57 62.84 8.88 11.07 17.57 13.95 17.95 14.05l.38.1.38-.1c.38-.1 9.07-2.98 17.95-14.05 14.73-18.19 17.57-44.17 17.57-62.84v-.1c0-.77-.38-1.35-1.13-1.35H99c-.19 0-21.44-.77-33.91-11.45-.94-.95-1.79-.19-1.79-.19C50.72 29.4 29.47 30.07 29.18 30.07"
          fill="#c9e3e6"
        />
        <path
          d="M98.89 30.36h.09c-.19 0-21.44-.77-33.91-11.45-.34-.34-.66-.46-.94-.47v90.35l.38-.1c.38-.1 9.07-2.98 17.95-14.05 14.73-18.19 17.57-44.17 17.57-62.84v-.1c-.01-.76-.38-1.34-1.14-1.34"
          fill="#b0bec5"
        />
        <circle cx={70.63} cy={14.44} r={1.93} fill="#37474f" />
        <circle cx={82.21} cy={19.67} r={1.93} fill="#37474f" />
        <circle cx={95.01} cy={23.21} r={1.93} fill="#37474f" />
        <circle cx={108.15} cy={25.14} r={1.93} fill="#37474f" />
        <circle cx={108.57} cy={36.94} r={1.93} fill="#37474f" />
        <circle cx={107.02} cy={50.76} r={1.93} fill="#37474f" />
        <circle cx={64.08} cy={118.11} r={1.93} fill="#37474f" />
        <circle cx={75.4} cy={112.71} r={1.93} fill="#37474f" />
        <circle cx={85.74} cy={102.71} r={1.93} fill="#37474f" />
        <circle cx={93.81} cy={91.27} r={1.93} fill="#37474f" />
        <circle cx={99.67} cy={79} r={1.93} fill="#37474f" />
        <circle cx={104.27} cy={64.65} r={1.93} fill="#37474f" />
        <circle cx={70.15} cy={13.8} r={1.93} fill="#b9e4ea" />
        <circle cx={81.73} cy={19.03} r={1.93} fill="#b9e4ea" />
        <circle cx={94.53} cy={22.57} r={1.93} fill="#b9e4ea" />
        <circle cx={107.66} cy={24.5} r={1.93} fill="#b9e4ea" />
        <circle cx={108.09} cy={36.3} r={1.93} fill="#b9e4ea" />
        <circle cx={106.53} cy={50.12} r={1.93} fill="#b9e4ea" />
        <circle cx={63.6} cy={117.47} r={1.93} fill="#eee" />
        <circle cx={74.92} cy={112.07} r={1.93} fill="#b9e4ea" />
        <circle cx={85.26} cy={102.07} r={1.93} fill="#b9e4ea" />
        <circle cx={93.33} cy={90.63} r={1.93} fill="#b9e4ea" />
        <circle cx={99.19} cy={78.36} r={1.93} fill="#b9e4ea" />
        <circle cx={103.79} cy={64.01} r={1.93} fill="#b9e4ea" />
        <circle cx={57.97} cy={14.44} r={1.93} fill="#2f7889" />
        <circle cx={46.39} cy={19.67} r={1.93} fill="#2f7889" />
        <circle cx={33.59} cy={23.21} r={1.93} fill="#2f7889" />
        <circle cx={20.45} cy={25.14} r={1.93} fill="#2f7889" />
        <circle cx={20.02} cy={36.94} r={1.93} fill="#2f7889" />
        <circle cx={21.58} cy={50.76} r={1.93} fill="#2f7889" />
        <circle cx={53.19} cy={112.71} r={1.93} fill="#2f7889" />
        <circle cx={42.86} cy={102.71} r={1.93} fill="#2f7889" />
        <circle cx={34.79} cy={91.27} r={1.93} fill="#2f7889" />
        <circle cx={28.92} cy={79} r={1.93} fill="#2f7889" />
        <circle cx={24.33} cy={64.65} r={1.93} fill="#2f7889" />
        <circle cx={57.54} cy={13.8} r={1.93} fill="#eee" />
        <circle cx={45.95} cy={19.03} r={1.93} fill="#eee" />
        <circle cx={33.16} cy={22.57} r={1.93} fill="#eee" />
        <circle cx={20.02} cy={24.5} r={1.93} fill="#eee" />
        <circle cx={19.59} cy={36.3} r={1.93} fill="#eee" />
        <circle cx={21.15} cy={50.12} r={1.93} fill="#eee" />
        <circle cx={52.76} cy={112.07} r={1.93} fill="#eee" />
        <circle cx={42.42} cy={102.07} r={1.93} fill="#eee" />
        <circle cx={34.36} cy={90.63} r={1.93} fill="#eee" />
        <circle cx={28.49} cy={78.36} r={1.93} fill="#eee" />
        <circle cx={23.89} cy={64.01} r={1.93} fill="#eee" />
      </svg>
    );
    export default SVGComponent;
