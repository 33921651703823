import React from "react";

export default function Header() {
    return (
      <div className="relative isolate px-6 pt-6 lg:px-4 mb-6">
      <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
      <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] "></div>
      </div>
      <div class="mx-auto max-w-2xl py-32 sm:py-48 lg:py-2">
      <div class="hidden sm:mb-8 sm:flex sm:justify-center">
      </div>
      <div class="text-center">
      <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">The Launchpad Protocol for Everyone!</h1>
      <p class="mt-6 text-lg leading-8 text-gray-600">We helps everyone to create their own tokens and token sales in few seconds. building by TowerSwap Finance</p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
      <a href="/launchpad/pools" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Get started</a>
      <a href="https://drive.google.com/file/d/10FayK8N1PMYerrf-yRGM1kRDTLIL8Esv" class="text-sm font-semibold leading-6 text-gray-900">Read Docs <span aria-hidden="true">→</span></a>
      </div>
      </div>
      </div>
      <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
      <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 "></div>
      </div>
      </div>
    );
  }
