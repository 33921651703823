import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 30 30"
    id="Layer_1"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <style>
      {
        ".st0{fill:#fd6a7e}.st2{fill:#8797ee}.st3{fill:#41a6f9}.st4{fill:#37e0ff}.st5{fill:#2fd9b9}.st6{fill:#f498bd}.st7{fill:#ffdf1d}.st8{fill:#c6c9cc}"
      }
    </style>
    <path
      d="M25 22v4c0 .6-.4 1-1 1H6c-.6 0-1-.4-1-1V13c0-.6.4-1 1-1h18c.6 0 1 .4 1 1v4h-4.5c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5H25zm-4.5-3.5c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1zM18.5 2C17.1 2 16 3.1 16 4.5S17.1 7 18.5 7 21 5.9 21 4.5 19.9 2 18.5 2zm-3.8 8c.2-.4.3-.8.3-1.3 0-1.7-1.3-3-3-3S9 7 9 8.7c0 .5.1.9.3 1.3h5.4z"
      style={{
        fill: "#17b978",
      }}
    />
  </svg>
);
export default SVGComponent;
