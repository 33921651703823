import { useEthers } from "@usedapp/core";
import PreviewDetails from "components/Common/PreviewDetails";
import Timer from "components/LockedAsset/Amount/Timer/Timer";
import { Contract } from "ethers";
import React, { useEffect, useState } from "react";
import PublicSaleAbi from "../../../config/abi/PublicSale.json";
import PublicSaleErcAbi from "../../../config/abi/PublicSaleErcAbi.json";
import PrivateSaleAbi from "../../../config/abi/PrivateSale.json";
import PrivateSaleErcAbi from "../../../config/abi/PrivateSaleErcAbi.json";
import PublicVestAbi from "../../../config/abi/PublicVesting.json";
import FairLaunchAbi from "../../../config/abi/FairlaunchSale.json";
import FairLaunchErcAbi from "../../../config/abi/FairlaunchErcAbi.json";
import useParticipated from "utils/getParticipated";
import { formatBigToNum } from "utils/numberFormat";
import { useModal } from "react-simple-modal-provider";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Web3 from "web3";
import getSaleInfo from "utils/getSaleInfo";


export default function SaleBox({ icon, sale, status, isFinished, isCancelled }) {
  const { account, library } = useEthers();
  const [allocated, setAllocated] = useState(0);
  const [vestingInfo, setVestingInfo] = useState([]);
  const [bnbFilled, setBnbFilled] = useState(0);
  const [bought, setBought] = useState(0);
  const [tgeReleasePercent, setTgeReleasePercent] = useState(0);
  const [cycleReleasePercent, setCycleReleasePercent] = useState(0);
  const [cycleDuration, setCycleDuration] = useState(0);
  const [saleInfo, setSaleInfo] = useState(null);
  const [tokensWithdrawn, setTokensWithdrawn] = useState(false);
  const participated = useParticipated(sale.saleAddress, account);
  // console.log("Private Sale Address:", sale.saleAddress);
  const { open: openLoadingModal, close: closeLoadingModal } =
  useModal("LoadingModal");
  const getUserParticipation = async () => {
    if (!sale) {
      return; // Return early if 'sale' is not available
    }

    let contract;

    if (sale.saleType === "public") {
      contract = new Contract(sale.saleAddress, PublicSaleAbi, library.getSigner());
    } else if (sale.saleType === "publicvesting" ) {
      contract = new Contract(sale.saleAddress, PublicVestAbi, library.getSigner());
    } else if (sale.saleType === "private") {
      contract = new Contract(sale.saleAddress, PrivateSaleAbi, library.getSigner());
    } else if (sale.saleType === "fairlaunch") {
      contract = new Contract(sale.saleAddress, FairLaunchAbi, library.getSigner());
    } else {
      // Handle other sale types or set 'contract' to an appropriate default value
      return;
    }

    const userParticipation = await contract.userToParticipation(account);
    // console.log("userParticipation:", userParticipation.toString());
    setBought(formatBigToNum(userParticipation[0].toString(), 18, 4));
    setAllocated(formatBigToNum(userParticipation[1].toString(), 18, 4));
    setTokensWithdrawn(userParticipation[2]);
  };


  const withdrawTokens = async () => {
    openLoadingModal();

    if (tokensWithdrawn) {
      toast.error("Tokens already withdrawn");
      closeLoadingModal();
      return;
    }

    if (participated[0] === false) {
      toast.error("You have not participated in this sale");
      closeLoadingModal();
      return;
    }

    let contract;

    const coinSymbol = sale.currency.symbol;
    try {
    if (sale.currency.symbol === coinSymbol) {
      if (sale.saleType === "public") {
        contract = new Contract(
          sale.saleAddress,
          PublicSaleAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "fairlaunch") {
        contract = new Contract(
          sale.saleAddress,
          FairLaunchAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "publicvesting" ) {
        contract = new Contract(
          sale.saleAddress,
          PublicVestAbi,
          library.getSigner()
        );
      }
    } else if (sale.saleType === "private") {
      contract = new Contract(
        sale.saleAddress,
        PrivateSaleAbi,
        library.getSigner()
      );
    }  else {
      if (sale.saleType === "public") {
        contract = new Contract(
          sale.saleAddress,
          PublicSaleErcAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "fairlaunch") {
        contract = new Contract(
          sale.saleAddress,
          FairLaunchErcAbi,
          library.getSigner()
        );
      }
    }
      if (contract && isCancelled) {
        const tx = await contract.withdrawUserFundsIfSaleCancelled();
        await tx.wait();
      } else {
        const tx = await contract.withdraw();
        await tx.wait();
      }

      toast.success("Tokens Withdrawn");
      window.location.reload();
    } catch (err) {
      console.log(err);
      if (isCancelled && err.reason === "execution reverted: Sale was cancelled") {
        // Handle the case where the sale is canceled
        toast.warning("Sale was canceled. Refund in progress.");
      } else {
        toast.error("Transaction Failed");
      }
      closeLoadingModal();
    }

    closeLoadingModal();
  };

  const withdrawuserfund = async () => {
    openLoadingModal();

    let contract;
    const coinSymbol = sale.currency.symbol;

    try {
      if (sale && sale.currency.symbol === coinSymbol && (sale.saleType === "public" || sale.saleType === "fairlaunch")) {
        contract = new Contract(sale.saleAddress, PublicSaleAbi, library.getSigner());

        if (contract && isCancelled) {
          const tx = await contract.withdrawUserFundsIfSaleCancelled();
          await tx.wait();
          toast.success("Fund Withdrawn");
        }
      } else {
        console.error("Invalid sale type or sale not available");
      }
    } catch (error) {
      // Handle errors
      console.error("Error in withdrawVestingTokens:", error);
    } finally {
      closeLoadingModal();
    }
};



  const withdrawVestingTokens = async () => {
    openLoadingModal();

    let contract;
    const coinSymbol = sale.currency.symbol;

    try {
      if (sale && sale.currency.symbol === coinSymbol && (sale.saleType === "private" || sale.saleType === "publicvesting" )) {
        contract = new Contract(sale.saleAddress, PrivateSaleAbi, library.getSigner());

        if (contract && isCancelled) {
          const tx = await contract.withdrawUserFundsIfSaleCancelled();
          await tx.wait();
        } else {
          const tx = await contract.withdraw();
          await tx.wait();
          toast.success("Tokens Withdrawn");
        }
      } else {
        console.error("Invalid sale type or sale not available");
      }
    } catch (error) {
      // Handle errors
      console.error("Error in withdrawVestingTokens:", error);
    } finally {
      closeLoadingModal();
    }
};



  const withdrawParticipation = async () => {
    openLoadingModal()
    if (participated && participated && participated[0] === false) {
      toast.error("You have not participated in this sale");
      closeLoadingModal();
      return;
    }
    const coinSymbol = sale.currency.symbol;
    let contract;
    if (sale.currency.symbol === coinSymbol) {
      contract = new Contract(
        sale.saleAddress,
        FairLaunchAbi,
        library.getSigner()
      );
    } else {
      contract = new Contract(
        sale.saleAddress,
        FairLaunchErcAbi,
        library.getSigner()
      );
    }

    try {
      const tx = await contract.withdrawParticipation();
      await tx.wait();
      toast.success("Participation Withdrawn");
    } catch (err) {
      toast.error("You Have Already Withdrawn Your Participation");
      closeLoadingModal()
    }
    closeLoadingModal()
  };
  // console.log(tokensWithdrawn, "tokensWithdrawn");
  useEffect(() => {
      if (sale) {
        getUserParticipation();
        // getUserVestingInfo();
      }
    }, [sale]);

    const firstRelease = parseFloat(sale.firstRelease);
    let tokenBought = parseFloat(bought);

  // Check if tokenBought is a string and contains commas
  if (typeof bought === 'string' && bought.includes(',')) {
      // Remove commas and convert to float
      tokenBought = parseFloat(bought.replace(/,/g, ''));
  }

  const rounds = [
  { round: 1, releaseDate: sale.releaseDate1, percent: sale.percent1 },
  { round: 2, releaseDate: sale.releaseDate2, percent: sale.percent2 },
  { round: 3, releaseDate: sale.releaseDate3, percent: sale.percent3 },
  { round: 4, releaseDate: sale.releaseDate4, percent: sale.percent4 },
];

useEffect(() => {
  const result = getSaleInfo(sale.saleAddress, sale.saleType).then((res) => {
    setSaleInfo(res);
    if (res && res.totalBNBRaised !== undefined && sale.softCap !== undefined && sale.softCap !== 0) {
      const bnbFilled = parseFloat(res.totalBNBRaised);
      setBnbFilled(bnbFilled);
    }
  });
}, []);

const calculated = (tokenBought / 100) * firstRelease;
const remainingToken = tokenBought - calculated;
const presaleAmount = sale.presalePrice;
const fairBought = (allocated * presaleAmount) / (bnbFilled / 10**18);
// console.log('bnbFilled:', bnbFilled / 10**18);
// console.log('presaleAmount:', presaleAmount);
// console.log('allocated:', allocated);
// console.log('fairTokenBought:', fairTokenBought);
// console.log('fairBought:', fairBought);


  return (
    <>
      <div className="px-9 pb-9 bg-white dark:bg-dark-1 rounded-[20px]">
      {sale.saleType === "public" || sale.saleType === "publicvesting"  || sale.saleType === "private" && (
        <div className="w-full flex justify-center">
          <div className="w-1/2 py-5 flex justify-center items-center border-b-2 border-primary-green ">
            <span className="font-bold text-primary-green">User Panel</span>
          </div>
        </div>
        )}

        <PreviewDetails
          name={"My Contribution"}
          value={allocated + " " + sale.currency.symbol}
        />
        {sale.saleType === "public" && (
        <PreviewDetails
          name={"My Reserved"}
          value={bought + " " + sale.token.tokenSymbol}
        />
        )}
        {sale.saleType === "fairlaunch"&& (
        <PreviewDetails
          name={"My Reserved"}
          value={fairBought + " " + sale.token.tokenSymbol}
        />
        )}
        {isFinished && participated && participated[0] && (
          <div className="flex flex-col items-center">
            <span className="font-medium text-gray dark:text-gray-dark text-sm mt-5">
              {tokensWithdrawn?"You has been claim":"Available to Claim"}
            </span>
            {sale.saleType === "public"  && (
            <div className="mt-3 flex">
              <img src={icon} alt="pool-icon" className="w-6 h-6 mr-2" />
              <span className="font-bold text-dark-text dark:text-light-text text-xl">
                {bought} {sale.token.tokenSymbol}
              </span>
            </div>
          )}
          {sale.saleType === "fairlaunch"  && (
          <div className="mt-3 flex">
            <img src={icon} alt="pool-icon" className="w-6 h-6 mr-2" />
            <span className="font-bold text-dark-text dark:text-light-text text-xl">
              {fairBought} {sale.token.tokenSymbol}
            </span>
          </div>
        )}
          </div>
        )}
        {((sale.saleType === "public" || sale.saleType === "fairlaunch") && status === "Ended" && isFinished || isCancelled) && participated && participated[0] && (
          <div className="mt-7">
            <button
            disabled={tokensWithdrawn}
              onClick={withdrawTokens}
              className="w-full bg-primary-green rounded-md text-white font-bold py-4 disabled:bg-dim-text "
            >
              {tokensWithdrawn ? "Tokens Withdrawn" : "Withdraw Fund"}
            </button>
          </div>
        )}
        {(( sale.saleType === "publicvesting"  || sale.saleType === "private") && status === "Ended" && isFinished || isCancelled) && participated && participated[0] && (
          <div className="flex flex-col items-center">
                <div className="mt-3 flex justify-between mb-3">
                <span className="ml-2 font-bold text-dark-text dark:text-light-text text-sm">
                  {isFinished ? 'TGE :' : 'Your Fund'}
                </span>
                <span className="ml-2 font-bold text-dark-text dark:text-light-text text-sm">
                  {isFinished ? `${calculated} ${sale.token.tokenSymbol}` : `${allocated} ${sale.currency.symbol}`}
                </span>
                </div>
                {sale.saleType === "private" && isFinished && (
                  <div className="mb-3 flex flex-col items-center">
                    <span className="text-sm font-medium text-gray dark:text-gray-dark">
                      {`TGE will be released on:`}
                    </span>
                    <Timer
                      date={new Date(sale.tgeRelaseDate * 1000).toLocaleString()}
                    />
                  </div>
                )}
                <button
                  onClick={() => withdrawVestingTokens()}
                  className="w-full bg-primary-green text-sm rounded-md text-white font-bold py-2 disabled:bg-dim-text"
                >
                  {isFinished ? 'CLAIM :' : 'WITHDRAW'}
                </button>
          </div>
        )}

        {(sale.saleType === "private" || sale.saleType === "publicvesting" ) && status === "Ended" && isFinished && participated && participated[0] && rounds.map((roundInfo, index) => {
          const isClaimable = new Date() >= new Date(roundInfo.releaseDate * 1000);

          // Calculate the release date for the next round
          const nextRoundReleaseDate =
            index < rounds.length - 1 ? rounds[index + 1].releaseDate : null;

          // Render the block only if it's time to claim
          if (isClaimable) {
            return (
              <div key={index} className="flex flex-col items-center rounded-sm border-lime-500">
                <div className="mb-3 mt-3">
                  <div className="flex justify-center mt-2">
                    <span className="text-sm font-medium text-gray dark:text-gray-dark">
                      {`Round ${roundInfo.round} ends in`}
                    </span>
                  </div>
                  <Timer
                    date={
                      nextRoundReleaseDate
                        ? new Date(nextRoundReleaseDate * 1000).toLocaleString()
                        : null
                    }
                  />
                </div>
                <button
                  onClick={() => withdrawVestingTokens()}
                  className="w-full bg-primary-green rounded-md text-sm text-white font-bold py-2"
                >
                  CLAIM {`${remainingToken / 100 * roundInfo.percent} ${sale.token.tokenSymbol}`}
                </button>
              </div>
            );
          }

          // If it's not time to claim, return null or an empty fragment
          return null;
        })}


        {status !== "Ended" && sale.saleType === "fairlaunch" && participated && participated[0] && (
          <div className="mt-7">
            <button
              onClick={withdrawParticipation}
              className="w-full bg-primary-green rounded-md text-white font-bold py-4"
            >
              Withdraw
            </button>
          </div>
        )}
      </div>
    </>
  );
}
