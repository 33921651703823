// Updated Networkswitch.js
// import { BSC } from '@usedapp/core';
import { BSC, Base, ShardeumChain, ZetaChain } from 'config/constants/testchain';

export const Networkswitch = [
  // {
  //   iconimg: "/images/headericons/BinanceSmartChainBadge.svg",
  //   title: "BSC",
  //   subtitle: "Connected",
  //   status: true,
  //   isActive: true,
  //   chainId: BSC.chainId,
  // },
  {
    iconimg: "/images/headericons/shm.svg",
    title: "SHARDEUM",
    subtitle: "Not Connected",
    status: false,
    isActive: false,
    chainId: ShardeumChain.chainId,
  },
  {
    iconimg: "/images/headericons/zeta.png",
    title: "ZETA",
    subtitle: "Not Connected",
    status: true,
    isActive: true,
    chainId: ZetaChain.chainId,
  },
  {
    iconimg: "/images/headericons/Base.png",
    title: "BASE",
    subtitle: "Not Connected",
    status: false,
    isActive: false,
    chainId: Base.chainId,
  },
];
