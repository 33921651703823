import { useState, useEffect } from 'react';
// import { BSC } from '@usedapp/core';
import { BSC, Base, ShardeumChain, ZetaChain } from 'config/constants/testchain';

export function useDefaultChainId() {
  const [defaultChainId, setDefaultChainId] = useState(() => {
    const storedChainId = localStorage.getItem('network-switch');
    if (storedChainId) {
      const items = JSON.parse(storedChainId);
      const activeItem = items.find((item) => item.isActive);

      if (activeItem) {
        if (activeItem.title === 'ZETA') {
          return ZetaChain.chainId;
        } else if (activeItem.title === 'BSC') {
          return BSC.chainId;
        } else if (activeItem.title === 'SHARDEUM') {
          return ShardeumChain.chainId;
        } else if (activeItem.title === 'BASE') {
          return Base.chainId;
        }
      }
    }

    const isPreferredBsc = true;
    return isPreferredBsc ? BSC.chainId : Base.chainId;
  });

  useEffect(() => {
    const handleStorageChange = (e) => {
      const network = localStorage.getItem('network-switch');
      if (network) {
        const items = JSON.parse(network);
        const activeItem = items.find((item) => item.isActive);

        if (activeItem) {
          if (activeItem.title === 'SHARDEUM') {
            setDefaultChainId(ShardeumChain.chainId);
          } else if (activeItem.title === 'BSC') {
            setDefaultChainId(BSC.chainId);
          } else if (activeItem.title === 'ZETA') {
            setDefaultChainId(ZetaChain.chainId);
          } else if (activeItem.title === 'BASE') {
            setDefaultChainId(Base.chainId);
          }
        }
      }

      //window.location.reload()
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return defaultChainId;
}
