import React, { useEffect, useMemo, useState } from 'react'
import Web3 from 'web3';
import { useModal } from 'react-simple-modal-provider'
import { useNavigate } from 'react-router-dom'
import { parseEther } from 'ethers/lib/utils'
import BackArrowSVG from '../../svgs/back_arrow'
import PreviewDetails from '../Common/PreviewDetails'
import PreviewHeader from '../Common/PreviewHeader'
import { formatBigToNum } from '../../utils/numberFormat'
import { FACTORY_ADDRESS } from '../../config/constants/address'
import { ethers } from 'ethers'
import { useEthers, useTokenAllowance, useTokenBalance } from '@usedapp/core'
import { parseUnits } from 'ethers/lib/utils'
import { Contract } from '@ethersproject/contracts'
import ERCAbi from 'config/abi/ERC20.json'
import LockFactoryAbi from 'config/abi/LockFactory.json'
import getFeeInfo from 'hooks/useFeeInfo'
import axios from 'axios'
import { BACKEND_URL } from 'config/constants/LaunchpadAddress'

export default function Preview({ locker, setActive, lockData }) {
  const navigate = useNavigate()
  const [date, setDate] = useState()
  const [days, setDays] = useState(0)
  const [feeInfo, setFeeInfo] = useState(null)
  const { open: openLoadingModal, close: closeLoadingModal } =
    useModal("LoadingModal");

  const { account, library, chainId } = useEthers()
  console.log("Use token allowance", lockData.tokenAddress, account, FACTORY_ADDRESS[chainId],chainId, "chainId")
  const allowance = useTokenAllowance(lockData.tokenAddress, account, FACTORY_ADDRESS[chainId], {
    refresh: 5,
  })
  const balance = useTokenBalance(lockData.tokenAddress, account, {
    refresh: 5,
  })
  const amountLock = useMemo(() => {
    if (typeof lockData.lockAmount === 'string') {
      return parseUnits(lockData.lockAmount, lockData.tokenDecimals)
    }
    return parseUnits(lockData.lockAmount.toString(), lockData.tokenDecimals)
  }, [lockData.lockAmount, lockData.tokenDecimals])

  useEffect(() => {
    async function fetchData() {
      const info = await getFeeInfo(chainId)
      setFeeInfo(info)
    }
    fetchData()
  }, [])
  useEffect(() => {
    if (lockData.unlockDate) {
      const date = new Date(lockData.unlockDate * 1000)
      setDate(date.toLocaleDateString())
      setDays(Math.ceil((date - new Date()) / (1000 * 60 * 60 * 24)))
    }
  }, [lockData.unlockDate])

  const needApprove = useMemo(() => {
    if (typeof allowance === 'undefined') {
      return true
    }
    return amountLock.gt(allowance)
  }, [amountLock, allowance])

  const isValid = useMemo(() => {
    if (typeof balance === 'undefined') {
      return true
    }
    return !needApprove && balance.gt(amountLock)
  }, [amountLock, needApprove, balance])

  const handleApprove = async () => {
    openLoadingModal()
    const contract = new Contract(lockData.tokenAddress, ERCAbi, library.getSigner())
    try {
      console.log("Approve", FACTORY_ADDRESS[chainId],chainId, "chainId")
      const approval = await contract.approve(FACTORY_ADDRESS[chainId], ethers.constants.MaxUint256)
      await approval.wait()
    } catch (error) {
      console.log(error, "error")
    }
    closeLoadingModal()
  }

  const handleLockToken = async () => {
    openLoadingModal();
    let deployedAddress;
    let lockObject;
    const contract = new Contract(FACTORY_ADDRESS[chainId], LockFactoryAbi, library.getSigner());
    const lockId = await contract.getTotalLiquidityLock();

    try {
      console.log("Lock token", FACTORY_ADDRESS[chainId], chainId, "chainId");
      console.log(parseEther(ethers.utils.formatEther(feeInfo.normalFee).toString()));
      console.log(account, lockData.tokenAddress, amountLock, lockData.unlockDate, lockData.image, "lockData");

      const createLock = await contract.createTokenLock(
        account,
        lockData.tokenAddress,
        amountLock,
        lockData.unlockDate,
        lockData.image,
        {
          value: parseEther(feeInfo.normalFee),
        },
      );

      await createLock.wait();

      deployedAddress = await contract.tokenLockIdToAddress(lockId.toNumber());
      lockObject = {
        lockId: lockId.toNumber(),
        lockAddress: deployedAddress,
        lockType: lockData.type,
        tokenAddress: lockData.tokenAddress,
        tokenName: lockData.tokenName,
        tokenSymbol: lockData.tokenSymbol,
        tokenDecimals: lockData.tokenDecimals,
        tokenSupply: lockData.tokenSupply,
        lockAmount: lockData.lockAmount,
        lockAmountBN: lockData.lockAmountBN,
        unlockDate: lockData.unlockDate,
        chainID: chainId,
        image: lockData.image,
        owner: account,
        isCancelled: false,
      };
      await axios.post(`${BACKEND_URL}/api/lock`, {
        lock: lockObject,
      })
      closeLoadingModal();
      navigate(`/locked-assets`);
    } catch (error) {
      console.log(error, "error");
      closeLoadingModal();
      return false; // Atau handle kesalahan sesuai kebutuhan Anda
    }
  };

  const handleLockLP = async () => {
    openLoadingModal()
    let deployedAddress;
    let lockObject;
    const contract = new Contract(FACTORY_ADDRESS[chainId], LockFactoryAbi, library.getSigner())
    const lockId = await contract.getTotalLiquidityLock();
    console.log("Lock LP", FACTORY_ADDRESS[chainId],chainId, "chainId")
    try {
      const createLock = await contract.createLiquidityLock(
        account,
        lockData.tokenAddress,
        amountLock,
        lockData.unlockDate,
        '',
        {
          value: feeInfo.liquidityFee,
        },
      )
      await createLock.wait()
      deployedAddress = await contract.liquidityLockIdToAddress(lockId.toNumber());
      lockObject = {
        lockId: lockId.toNumber(),
        lockAddress: deployedAddress,
        lockType: lockData.type,
        tokenAddress: lockData.tokenAddress,
        tokenName: lockData.tokenName,
        tokenSymbol: lockData.tokenSymbol,
        token0symbol: lockData.token0.symbol,
        token1symbol: lockData.token0.symbol,
        tokenDecimals: lockData.tokenDecimals,
        tokenSupply: lockData.tokenSupply,
        lockAmount: lockData.lockAmount,
        lockAmountBN: lockData.lockAmountBN,
        unlockDate: lockData.unlockDate,
        chainID: chainId,
        image: lockData.image,
        owner: account,
        liquidity: lockData.liquidity,
      };
      await axios.post(`${BACKEND_URL}/api/lock`, {
        Lock: lockObject,
        liquidity: true,
      })
      console.log(lockData, "lockData");
      closeLoadingModal()
      navigate(`/locked-assets`)
      return
    } catch (error) {
      closeLoadingModal()
      return false
    }
  }

//   const handleSubmit = async () => {
//     openLoadingModal();
//     const web3 = new Web3(window.ethereum);
//     const chainId = await web3.eth.getChainId();
//     let deployedAddress;
//     let lockObject;
//
//     if (lockData.type === "public") {
//       const coinName = lockData.tokenName;
//       if (lockData.tokenName === coinName) {
//         if(chainId === chainId) {
//         lockObject = await handleLockToken(
//           lockData,
//           library,
//           account,
//          chainId,
//           closeLoadingModal
//         );
//        }
//       } else {
//         if(chainId === chainId) {
//           console.log("mainnet",chainId)
//         lockObject = await handleLockLP(
//           lockData,
//           library,
//           account,
//           chainId,
//           closeLoadingModal
//         );
//        }
//       }
//       console.log(lockObject, "lockObject");
//       await axios.post(`${BACKEND_URL}/api/lock`, {
//         Lock: lockObject,
//         liquidity: true,
//         chainId: chainId,
//       })
//     }
//     closeLoadingModal();
//     //redirect to home page
//     window.location.href = "/";
//  };

  const tokenSymbol = useMemo(() => {
      return lockData.type === 'lptoken' ? `${lockData.token0.symbol}/${lockData.token1.symbol}` : lockData.tokenSymbol
  }, [lockData]);


  return (
    <div className="-mt-10">
      {locker ? <PreviewHeader heading={'Token address Details'} /> : <PreviewHeader heading={'LP Details'} />}

      <div className="flex flex-col">
        {locker ? (
          <>
            <PreviewDetails name="Name" value={lockData.tokenName} />
            <PreviewDetails name="Symbol" value={lockData.tokenSymbol} />
            <PreviewDetails name="Decimals" value={lockData.tokenDecimals} />
            <PreviewDetails
              name="Total Supply"
              value={`${formatBigToNum(lockData.tokenSupply, lockData.tokenDecimals)} ${lockData.tokenSymbol}`}
            />
          </>
        ) : (
          <>
            <PreviewDetails name="Quote Pair" value={lockData.token0.symbol} />
            <PreviewDetails name="Base Pair" value={lockData.token1.symbol} />
            <PreviewDetails name="Symbol" value={tokenSymbol} />
            <PreviewDetails
              name="LP Supply"
              value={`${formatBigToNum(lockData.tokenSupply, lockData.tokenDecimals)} ${tokenSymbol}`}
            />
            <PreviewDetails name="Dex Listed" value={lockData.factory} />
          </>
        )}
      </div>

      <PreviewHeader heading={'Lock Details'} />

      <div className="flex flex-col">
        <PreviewDetails name={'Amount to be Locked'} value={`${lockData.lockAmount.toLocaleString()} ${tokenSymbol}`} />
        <PreviewDetails name={'Lock Period (Days)'} value={days} />
        <PreviewDetails name={'Unlock Date'} value={date} />
      </div>

      <div className="mt-10">
        <div className="flex justify-end items-center mb-10">
          <button
            className="bg-white dark:bg-transparent mr-5 flex items-center gap-2 py-[10px] px-5"
            onClick={() => setActive('Lock details')}
          >
            <BackArrowSVG className="fill-dark-text dark:fill-light-text" />
            <span className="font-gilroy font-medium text-sm text-dark-text dark:text-light-text">Go Back</span>
          </button>
          {needApprove ? (
            <>
              <button
                className="bg-primary-green hover:opacity-40 disabled:bg-light-text text-white font-gilroy font-bold px-8 py-3 rounded-md"
                onClick={handleApprove}
              >
                Approve Token
              </button>
            </>
          ) : locker ? (
            <>
              <button
                // disabled={!isValid}
                className="bg-primary-green hover:opacity-40 disabled:bg-light-text text-white font-gilroy font-bold px-8 py-3 rounded-md"
                onClick={handleLockToken}
              >
                Lock Token
              </button>
            </>
          ) : (
            <>
              <button
                // disabled={!isValid}
                className="bg-primary-green hover:opacity-40 disabled:bg-light-text text-white font-gilroy font-bold px-8 py-3 rounded-md"
                onClick={handleLockLP}
              >
                Lock LP Token
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
