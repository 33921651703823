import React from "react";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import Features from "./Components/features";
import Data from "./Components/data";
import Header from "./Components/header";
import Team from "./Components/team";
// import Roadmap from "./Components/Roadmap";

export default function Home({ totalSales }) {
  return (
    <BaseLayout title={"Home"}>
      <Header />
      {/*<Roadmap />*/}
      <Data totalSales={totalSales} />
      <Features />
      {/*<Team />*/}
    </BaseLayout>
  );
}
