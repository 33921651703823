import PublicAbi from '../config/abi/PublicLaunchpadAbi.json';
import FairAbi from '../config/abi/FairLaunchpadAbi.json';
import { Public_FACTORY, PublicVesting } from "config/constants/LaunchpadAddress";
import Web3 from 'web3';
import { parseEther, parseUnits } from 'ethers/lib/utils';
import { Private_FACTORY } from 'config/constants/LaunchpadAddress';



async function getCalcMax(saleObject, token, saleType) {
  const web3 = new Web3(window.ethereum);
  const chainId = await web3.eth.getChainId();
  const publicFactory = Public_FACTORY[chainId];
  const privateFactory = Private_FACTORY[chainId];
  const publicVestFactory = PublicVesting[chainId];
  let contract = null;
  if (chainId === chainId) {
    if (saleType === 'public') {
      contract = new web3.eth.Contract(PublicAbi, publicFactory);
    }
    else if (saleType === 'private') {
      console.log("private")
      contract = new web3.eth.Contract(PublicAbi, privateFactory);
    }
    else if (saleType === 'Standardvesting') {
      console.log("publicvesting" )
      contract = new web3.eth.Contract(PublicAbi, publicVestFactory);
    }
  }

  try {
  const calcMax = await contract.methods.calculateMaxTokensForLiquidity(
    parseEther(saleObject.hardCap.toString()).toString(),
    (saleObject.amountLiquidity * 100).toString(),
    parseUnits(saleObject.listing.toString(), token.tokenDecimals).toString(),
    token.tokenDecimals.toString(),
  ).call();
  return calcMax;
}
catch (err) {
  console.log(err)
}
}

export default getCalcMax;
