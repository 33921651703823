import React from "react";
import ModalField from "./ModalField";
import { useEffect, useState } from "react";
import { BigNumber, Contract, ethers } from "ethers";
import { useEtherBalance, useEthers } from "@usedapp/core";
import PublicSaleAbi from "../../../config/abi/PublicSale.json";
import PublicSaleErcAbi from "../../../config/abi/PublicSaleErcAbi.json";
import PrivateSaleAbi from "../../../config/abi/PrivateSale.json";
import PrivateSaleErcAbi from "../../../config/abi/PrivateSaleErcAbi.json";
import FairLaunchAbi from "../../../config/abi/FairlaunchSale.json";
import FairLaunchErcAbi from "../../../config/abi/FairlaunchErcAbi.json";
import PublicVestAbi from "../../../config/abi/PublicVesting.json";
import { formatEther, parseEther } from "ethers/lib/utils";
import { API_URL, API_KEY } from "config/constants/api";
import axios from "axios";
import getSaleInfo from "utils/getSaleInfo";
import usePublicErcSaleInfo from "utils/getPublicErcSaleInfo";
import usePrivateSaleInfo from "utils/getPrivateSaleInfo";
import usePrivateErcSaleInfo from "utils/getPrivateErcSaleInfo";
import useFairlaunchSaleInfo from "utils/getFairLaunchSaleInfo";
import useFairlaunchErcSaleInfo from "utils/getFairLaunchErcSaleInfo";
import { formatBigToNum } from "utils/numberFormat";
import ERC20 from "config/abi/ERC20.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getAmountParticipated from "utils/getAmountParticipated";
import Web3 from "web3";
import { useModal } from "react-simple-modal-provider";
import { BACKEND_URL } from "config/constants/LaunchpadAddress";
import { useNativePrice } from 'hooks/useNativePrice'
import { useDefaultChainId } from "config/useDefaultChainId";

export default function Modal({
  showModal,
  from_symbol,
  from_icon,
  to_icon,
  to_symbol,
  sale,
  objId,
  // account,
}) {
  const { library } = useEthers();
  const [amount, setAmount] = useState(0);
  console.log("amount:", amount)
  const chainId = useDefaultChainId();
  const [nativePrice, setNativePrice] = useState();
  const nativePriceData = useNativePrice(chainId);
  console.log("nativePriceData:", nativePriceData)
  const [usdAmount, setUsdAmount] = useState(sale.minAllocation * nativePrice);
  console.log("usdAmount:", usdAmount);
  const [tokenPrice, setTokenPrice] = useState(sale.presalePrice);
  const { open: openLoadingModal, close: closeLoadingModal } =
    useModal("LoadingModal");
  // const sale_info_public_erc = usePublicErcSaleInfo(sale.saleAddress);
  console.log(sale, "saleis");
  let account = "";
  const [balanceBNB, setBalanceBNB] = useState(null);
  const [balance, setBalance] = useState(0);
  const [acct, setAcct] = useState("");
  let bought = "";

  useEffect(() => {
    async function connectWallet() {
      if (typeof window.ethereum !== "undefined") {
        // Instance web3 with the provided information
        const web3 = new Web3(window.ethereum);
        try {
          // Request account access
          await window.ethereum.enable();
          // Wallet connected successfully
          // You can perform further actions here
          account = await web3.eth.getAccounts();
          setAcct(account[0]);
          web3.eth.getBalance(account[0]).then((res) => {
            setBalanceBNB(res);
          });
        } catch (e) {
          // User denied access or error occurred
          // Handle the error or show appropriate message
        }
      }
    }

    connectWallet();
  }, []);

  const [tokenAmount, setTokenAmount] = useState(0);


  useEffect(() => {
    console.log("currenct address", sale.currency.address);
    console.log(acct, "acct");
    const coinSymbol = sale.currency.symbol;
    if (sale.currency.symbol !== coinSymbol) {
      console.log(sale.currency.address, "sale.currency.address");
      const contract = new Contract(
        sale.currency.address,
        ERC20,
        library.getSigner()
      );
      const getBalance = async () => {
        try {
          if (!acct) return;
          const balance = await contract.balanceOf(acct);
          const balanceString = formatBigToNum(balance, 18);
          setBalance(parseFloat(balanceString.replace(/,/g, "")));
        } catch (e) {
          console.log(e);
        }
      };
      getBalance();
    } else {
      if (!balanceBNB) return;
      setBalance(formatEther(balanceBNB).substring(0, 6));
    }
  }, [balanceBNB, acct]);

  //get user balanceBNB
  const handleSubmit = async () => {
    //user balanceBNB
    //check if sale started
    bought = await getAmountParticipated(sale.saleAddress, acct, sale.saleType);
    console.log("bought", bought);
    const userAllocation = formatBigToNum(bought[0].toString(), 19, 4);

    if (userAllocation >= sale.maxAllocation) {
      console.log(
        "userAllocation",
        userAllocation,
        "sale.maxAllocation",
        sale.maxAllocation
      );
      toast.error("You have reached the maximum allocation");
      return;
    }
    // console.log(sale)
    const start = new Date(sale.startDate);
    const now = new Date();
    // console.log("start", start, "now", now);
    if (now < start) {
      toast.error("Sale not started yet");
      return;
    }

    if (parseFloat(amount) > parseFloat(balance)) {
      toast.error("Insufficient balance");
      return;
    }
    const saleContractAddress = sale.saleAddress;

    let abi;
    const coinSymbol = sale.currency.symbol;
    if (sale.currency.symbol === coinSymbol) {
      if (sale.saleType === "public") {
        abi = PublicSaleAbi;
      } else if (sale.saleType === "publicvesting" ) {
        abi = PublicVestAbi;
      } else if (sale.saleType === "private") {
        abi = PrivateSaleAbi;
      } else if (sale.saleType === "fairlaunch") {
        abi = PrivateSaleAbi;
      }
    } else {
      if (sale.saleType === "public") {
        abi = PublicSaleErcAbi;
      } else if (sale.saleType === "private") {
        abi = PrivateSaleErcAbi;
      } else if (sale.saleType === "fairlaunch") {
        abi = PrivateSaleErcAbi;
      }
    }

    const contract = new Contract(
      saleContractAddress,
      abi,
      library.getSigner()
    );
    // console.log("contract", contract);
    const amountBuy = parseEther(amount.toString()).toString();
    openLoadingModal();
    try {
      const coinSymbol = sale.currency.symbol;
      if (sale.currency.symbol !== coinSymbol) {
        const approvalContract = new Contract(
          sale.currency.address,
          ERC20,
          library.getSigner()
        );
        try {
          const approval = await approvalContract.approve(
            sale.saleAddress,
            ethers.constants.MaxUint256
          );
          await approval.wait();
        } catch (err) {
          console.log(err);
        }
      }

      if (sale.currency.symbol === coinSymbol) {
        const tx = await contract.participate({
          value: amountBuy, gasLimit: 300000,
        });
        await tx.wait();
      } else {
        console.log("acct", acct, amountBuy);
        const tx = await contract.participate(amountBuy);
        await tx.wait();
      }
      try {
        const saleInfo = await getSaleInfo(
          sale.saleAddress,
          sale.saleType,
          sale.currency.symbol
        );
        let res;
        const coinSymbol = sale.currency.symbol;
        if (sale.saleType === "public" || sale.currency.symbol === coinSymbol) {
          res = await saleInfo.totalBNBRaised;
        } else if (sale.saleType === "private") {
          res = await saleInfo.totalBNBRaised;
        } else if (sale.saleType === "fairlaunch") {
          res = await saleInfo.totalBNBRaised;
        }
        res = BigNumber.from(res);
        let percents;
        if (sale.saleType === "fairlaunch") {
          const res = BigNumber.from(await saleInfo.totalBNBRaised);
          percents = res.mul(100).div(saleInfo.softCap);
          console.log("percents:", percents);
        } else if (sale.saleType === "public" || sale.saleType === "private" || sale.saleType === "publicvesting") {
          const res = BigNumber.from(await saleInfo.totalBNBRaised);
          percents = res.mul(100).div(saleInfo.hardCap);
        }
        const filled = formatBigToNum(percents.toString(), 0, 1);

        const finalSaleObject = {
          saleId: sale.saleId,
          saleAddress: sale.saleAddress,
          saleType: sale.saleType,
          github: sale.github,
          website: sale.website,
          twitter: sale.twitter,
          whitepaper: sale.whitepaper,
          linkedin: sale.linkedin,
          discord: sale.discord,
          telegram: sale.telegram,
          kyc: sale.kyc,
          audit: sale.audit,
          safu: sale.safu,
          youtube: sale.youtube,
          image: sale.image,
          name: sale.name,
          description: sale.description,
          tags: sale.tags,
          tags2: sale.tags2,
          token: sale.token,
          firstRelease: sale.firstRelease,
          percent1: sale.percent1,
          percent2: sale.percent2,
          percent3: sale.percent3,
          percent4: sale.percent4,
          tgeRelaseDate: sale.tgeRelaseDate,
          releaseDate1: sale.releaseDate1,
          releaseDate2: sale.releaseDate2,
          releaseDate3: sale.releaseDate3,
          releaseDate4: sale.releaseDate4,
          minAllocation: sale.minAllocation,
          maxAllocation: sale.maxAllocation,
          amountLiquidity: sale.amountLiquidity,
          listing: sale.listing,
          lockup: sale.lockup,
          presalePrice: sale.presalePrice,
          fairAmount: sale.fairAmount,
          endDate: sale.endDate,
          startDate: sale.startDate,
          hardCap: sale.hardCap,
          softCap: sale.softCap,
          unsoldToken: sale.unsoldToken,
          currency: sale.currency,
          dex: sale.dex,
          whiteisting: sale.whiteisting,
          whiteListedAddresses: sale.whiteListedAddresses,
          owner: sale.owner,
          isFinished: sale.isFinished,
          chainID: sale.chainID,
          filledPercent: filled,
        };
        console.log(finalSaleObject, "finalSaleObject");
        const res2 = await axios.put(`${BACKEND_URL}/api/sale/${objId}`, {
          sale: finalSaleObject,
        });
        console.log(res2, "res2");
      } catch (err) {
        console.log(err);
      }
      // closeLoadingModal();
      toast.success("Transaction successful");
      // window.history.back();
      showModal(false);
    } catch (err) {
      toast.error("Transaction failed");
      console.log(err);
    }
    closeLoadingModal();
  };

  const handleInput = async (e) => {
    setAmount(Number(e.target.value));
    setUsdAmount((Number(e.target.value) * nativePrice).toFixed(3));
    setTokenAmount((Number(e.target.value) * tokenPrice).toFixed(5));
  };

  const handleMax = () => {
    //balanceBNB to number everything after , is not removed
    if (balanceBNB === null) {
      toast.error("Connect wallet first");
      return;
    }
    //if balance is less than max allocation show error
    console.log(parseFloat(balance), parseFloat(sale.maxAllocation));
    if (parseFloat(balance) < parseFloat(sale.maxAllocation)) {
      toast.error("Insufficient balance");
      return;
    }
    let bal = balance;
    const amt = parseFloat(sale.maxAllocation);
    setAmount(amt);
    console.log(amt);
    setUsdAmount((amt * nativePrice).toFixed(3));
    console.log(amt * tokenPrice);
    setTokenAmount((amt * tokenPrice).toFixed(5));
  };
  const handleHalf = () => {
    if (balanceBNB === null) {
      toast.error("Connect wallet first");
      return;
    }
    //if balance is less than max allocation show error

    if (parseFloat(balance) < parseFloat(sale.maxAllocation) / 2) {
      toast.error("Insufficient balance");
      return;
    }
    let bal = balance;
    const amt = parseFloat(sale.maxAllocation);
    setAmount(amt / 2);
    setUsdAmount(((amt / 2) * nativePrice).toFixed(3));
    setTokenAmount(((amt / 2) * tokenPrice).toFixed(5));
  };

  useEffect(() => {
  let nativePrice; // Declare nativePrice variable outside of if-else blocks

  if (chainId === 8082) {
    nativePrice = nativePriceData?.shardeum?.usd;
  } else if (chainId === 56) {
    nativePrice = nativePriceData?.binancecoin?.usd;
  } else if (chainId === 7000) {
    nativePrice = nativePriceData?.zetachain?.usd;
  } else if (chainId === 8453) {
    nativePrice = nativePriceData?.ethereum?.usd;
  }

  if (typeof nativePrice === 'number') {
    const formattedNativePrice = nativePrice.toFixed(0);
    setNativePrice(formattedNativePrice);
    console.log('nativePrice: ', formattedNativePrice);
  } else {
    console.log('nativePrice is not a valid number:', nativePrice);
  }
}, [chainId, nativePriceData]);


  return (
    <>
      <div
        className={`w-screen h-screen flex backdrop-blur-[7px] flex-col justify-center items-center bg-[#F2F3F5] dark:bg-dark dark:bg-opacity-40 bg-opacity-40`}
      >
        <div className="w-[90%] max-w-[420px] rounded-[10px] px-9 py-5 bg-white dark:bg-dark-1">
          <div className="flex justify-between items-center  ">
            <span className="text-dark-text dark:text-light-text font-gilroy font-semibold text-lg">
              Join Pool
            </span>

            <div
              className="flex items-center cursor-pointer"
              onClick={() => showModal(false)}
            >
              <span className="text-sm font-gilroy font-semibold text-dark-text dark:text-light-text mr-2">
                Close
              </span>
              <div className="flex justify-center items-center bg-[#E56060] text-[#E56060] bg-opacity-10 rounded-full w-[15px] h-[15px]">
                &#10005;
              </div>
            </div>
          </div>

          <div className="mt-7">
            <div className="flex justify-between items-center">
              <span className="font-semibold text-sm text-gray dark:text-gray-dark">
                Amount {sale.currency.symbol}
              </span>

              <span className="font-medium text-sm text-dim-text dark:text-dim-text-dark">
                Balance:
                <span className="text-dark-text dark:text-light-text">
                  {balance && balance.toLocaleString()}
                </span>
              </span>
            </div>
          </div>
          <div className="mt-[10px] flex justify-between items-center rounded-md bg-[#F5F1EB] dark:bg-dark-3 px-5 py-5">
            <div className="flex flex-col">
              <input
                className="bg-transparent outline-none text-sm font-medium text-dark-text dark:text-light-text"
                type="number"
                placeholder="0.0"
                onChange={handleInput}
                value={amount}
                step={0.001}
                max={sale.maxAllocation}
                min={sale.minAllocation}
              />

              <span className="mt-3 text-sm font-medium text-gray dark:text-gray-dark">
                ~ ${usdAmount}
              </span>
            </div>

            <div className="border-l border-dashed pl-5 border-dim-text dark:border-dim-text-dark ">
              <button
                onClick={handleHalf}
                className="rounded-sm bg-[#C29D46] bg-opacity-[0.08] py-[2px] px-4 text-[#C89211] text-sm onhover:cursor-pointer"
              >
                Half
              </button>

              <div
                onClick={handleMax}
                className="mt-3 rounded-sm bg-primary-green bg-opacity-[0.08] py-[2px] px-4 text-primary-green text-sm"
              >
                Max
              </div>
            </div>
          </div>
          {(sale.saleType === "public" || sale.saleType === "publicvesting" || sale.saleType === "private") && (
          <div className="flex justify-center my-7">
            <img src="/images/arrows/arrow_down_green.svg" alt="arrow down" />
          </div>
          )}
          {(sale.saleType === "public" || sale.saleType === "publicvesting" || sale.saleType === "private") && (
          <div className="mt-7">
            <span className="font-semibold text-sm text-gray dark:text-gray-dark">
              You'll get
            </span>
          </div>
          )}
          {(sale.saleType === "public" || sale.saleType === "publicvesting" || sale.saleType === "private") && (
          <div className="mt-[10px]  rounded-md bg-[#F5F1EB] dark:bg-dark-3 px-5 py-5">
            <div className="flex justify-between items-center">
              <span className="font-bold text-xl text-dark-text dark:text-light-text">
                {parseFloat(tokenAmount)} {sale.token.tokenSymbol}
              </span>
              <span className="text-sm font-medium text-gray dark:text-gray-dark">
                ~ $---
              </span>
            </div>
          </div>
          )}
        </div>

        <div className="w-[90%] max-w-[420px]  mt-1">
          <button
            className="w-full bg-primary-green text-white py-5 rounded-md font-gilroy font-bold text-xl"
            onClick={handleSubmit}
          >
            Buy
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
