import PublicSaleAbi from "config/abi/PublicSale.json";
import PrivateSaleAbi from "config/abi/PrivateSale.json";
import PrivateSaleErcAbi from "config/abi/PrivateSaleErcAbi.json";
import FairLaunchAbi from "config/abi/FairlaunchSale.json";
import PublicVestAbi from "config/abi/PublicVesting.json";
import Web3 from "web3";

async function getSaleInfo(saleAddress, saleType, coinSymbol) {
  console.log(saleAddress, saleType, coinSymbol);

  try {
    const web3 = new Web3(window.ethereum);
    await window.ethereum.enable();

    let contract;

    if (saleType === 'private') {
      if (coinSymbol === coinSymbol) {
        contract = new web3.eth.Contract(PrivateSaleAbi, saleAddress);
      } else {
        contract = new web3.eth.Contract(PrivateSaleErcAbi, saleAddress);
      }
    } else if (saleType === 'public') {
      contract = new web3.eth.Contract(PublicSaleAbi, saleAddress);
    } else if (saleType === 'publicvesting') {
      contract = new web3.eth.Contract(PublicVestAbi, saleAddress);
    } else if (saleType === 'fairlaunch') {
      contract = new web3.eth.Contract(FairLaunchAbi, saleAddress);
    }

    const sale = await contract.methods.sale().call();
    console.log("Sale Information:", sale);

    // Use "totalBNBRaised" as the filled percentage for private sales
    const filledPercent = sale.totalBNBRaised;
    console.log("Filled Percent:", filledPercent);

    return sale;
  } catch (err) {
    console.log(err);
  }
}

export default getSaleInfo;
