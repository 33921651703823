import React, { useState, useEffect } from "react";
import axios from "axios";
import { BACKEND_URL } from "config/constants/LaunchpadAddress";

export default function Data() {
  const [totalSale, setTotalSale] = useState(0);
  const [uniqueProjects, setUniqueProjects] = useState([]);

  useEffect(() => {
    async function fetchTotalSale() {
      try {
        const res = await axios.get(`${BACKEND_URL}/api/sale`);
        const pools = res.data;

        console.log("All Pools:", pools);

        // Filter sales yang memiliki status 'Live', 'Ended', atau 'Upcoming'
        const filteredSales = pools.filter(
          (sale) => sale.sale.status === 'Live' || sale.sale.status === 'Upcoming' || sale.sale.status === 'Ended'
        );
        const allSales = filteredSales;

        console.log("Filtered Sales:", filteredSales);

        // Mendapatkan jumlah total sale
        const totalSaleAmount = filteredSales.length;
        setTotalSale(totalSaleAmount);

        // Hitung proyek unik
        const projects = [...new Set(filteredSales.map((sale) => allSales))];
        setUniqueProjects(projects);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchTotalSale();
  }, []);

  return (
    <div className="bg-white py-24 sm:py-32">
    <div class="font-semibold text-3xl mb-10 flex items-center justify-center gap-x-6">
    STATISTICS
    </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <dl className=" gap-x-8 gap-y-16 text-center ">
          <div className="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt className="text-base leading-7 text-gray-600">Project Launched</dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">{totalSale}</dd>
          </div>
          {/*<div className="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt className="text-base leading-7 text-gray-600">Participant</dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">3.500</dd>
          </div>
          <div className="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt className="text-base leading-7 text-gray-600">Fund Investment</dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">$500,000</dd>
          </div>*/}
        </dl>
      </div>
    </div>
  );
}
