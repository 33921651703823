import React, { useState, useEffect } from 'react';
import { useEthers } from '@usedapp/core';
import { ethers } from 'ethers';
import Timer from 'components/Launchpad/Pools/Subcomponents/Timer';
import TokenClaimContract from 'config/abi/TokenClaimContract.json';
import { TWSDROP_ADDRESS } from 'config/constants/LaunchpadAddress';
import BaseLayout from '../../components/BaseLayout/BaseLayout';
import { useDefaultChainId } from 'config/useDefaultChainId';

// ... (your imports)

function App() {
  const { library, account } = useEthers(); // Destructure 'account' from useEthers
  const chainId = useDefaultChainId();
  const [contract, setContract] = useState(null);
  const [claimableAmount, setClaimableAmount] = useState(0);
  const [claimStart, setClaimStart] = useState(0);
  const [claimEnd, setClaimEnd] = useState(0);

  useEffect(() => {
    const initEthers = async () => {
      try {
        const claimContractAddress = TWSDROP_ADDRESS[chainId];
        console.log('Chain ID:', chainId);
        console.log('Claim Contract Address:', claimContractAddress);

        const instance = new ethers.Contract(
          claimContractAddress,
          TokenClaimContract.abi,
          library.getSigner(account)
        );
        setContract(instance);

        // Retrieve claim start and end times
        const start = await instance.claimStartTime();
        const end = await instance.claimEndTime();
        console.log('start:', start.toLocaleString());
        console.log('end:', end.toLocaleString());
        setClaimStart(start.toNumber());
        setClaimEnd(end.toNumber());
      } catch (error) {
        console.error('Error initializing ethers:', error);
      }
    };

    initEthers();
  }, [library, chainId]);

  const getCurrentTimestamp = () => Math.floor(Date.now() / 1000);

  const handleClaimTokens = async () => {
    if (contract) {
      try {
        // Calculate claim fee
        const claimFee = await contract.methods.calculateClaimFee().call();

        // Send transaction to claim tokens
        await contract.methods.claimTokens().send({ from: account, value: claimFee }); // Use 'account' instead of 'accounts[0]'

        // Update claimable amount after a successful claim
        const newClaimableAmount = await contract.methods.getClaimableAmount(account).call(); // Use 'account' instead of 'accounts[0]'
        console.log('newClaimableAmount:', newClaimableAmount);
        setClaimableAmount(newClaimableAmount);
      } catch (error) {
        console.error('Error claiming tokens:', error);
      }
    }
  };

  return (
    <BaseLayout title={'Claim'}>
      <div className="container mx-auto m-8 border rounded-lg shadow-lg py-4">
        <h1 className="font-semibold text-3xl mb-10 flex items-center justify-center gap-x-6">Zealy TWS Airdrop</h1>

        {library && account && (
          <div className="font-semibold text-3xl mb-10 flex flex-col items-center justify-center">
            <p className="font-semibold text-lg mb-2">Connected Account:</p>
            <p className="font-semibold text-base mb-10 border border-gray-300 rounded p-4 shadow-md">{account}</p>
            <p className="font-semibold text-lg mb-2">My Airdrop Balance:</p>
            <p className="font-semibold text-lg mb-10 border border-gray-300 rounded p-4 shadow-md">${claimableAmount} TWS</p>

            {/* Display message based on the claim period status */}
            {getCurrentTimestamp() < claimStart ? (
              <p className="text-red-500 mb-2">Claim period has not started yet</p>
            ) : getCurrentTimestamp() > claimEnd ? (
              <p className="text-red-500 mb-2">Claim period has ended</p>
            ) : (
              <>
                <Timer date={new Date(claimEnd * 1000)} />
                <button
                  className="bg-blue-500 text-lg text-white px-4 py-2 rounded"
                  onClick={handleClaimTokens}
                >
                  Claim Tokens
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </BaseLayout>
  );
}

export default App;
