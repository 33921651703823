import * as React from "react";
const SVGComponent = (props) => (
  <svg
    id="Uploaded to svgrepo.com"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    {...props}
  >
    <style>{".open_een{fill:#0b1719}"}</style>
    <path
      className="open_een"
      d="M26.5 20h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zm0 3h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z"
    />
    <path
      className="open_een"
      d="M25.5 14H24v-4a7.941 7.941 0 0 0-2.429-5.742 7.93 7.93 0 0 0-5.816-2.255C11.479 2.132 8 5.86 8 10.314V14H6.5c-.827 0-1.5.673-1.5 1.5v13c0 .827.673 1.5 1.5 1.5h19c.827 0 1.5-.673 1.5-1.5v-1c0-.827-.673-1.5-1.5-1.5h-2a.5.5 0 0 0 0 1h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-19a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 .5-.5h2a.5.5 0 0 0 .5-.5v-4.186c0-3.919 3.043-7.199 6.785-7.311a6.923 6.923 0 0 1 5.09 1.973A6.946 6.946 0 0 1 23 10v4.5a.498.498 0 0 0 .5.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4c-.827 0-1.5.673-1.5 1.5v8a.5.5 0 0 0 1 0v-8a.5.5 0 0 1 .5-.5h4c.827 0 1.5-.673 1.5-1.5v-1c0-.827-.673-1.5-1.5-1.5z"
    />
    <path
      className="open_een"
      d="M21 14.5V10c0-2.757-2.243-5-5-5s-5 2.243-5 5v4.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5zm-1-.5h-8v-4c0-2.206 1.794-4 4-4s4 1.794 4 4v4zm6.5 6h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zm0 3h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z"
    />
    <path
      className="open_een"
      d="M25.5 14H24v-4a7.941 7.941 0 0 0-2.429-5.742 7.93 7.93 0 0 0-5.816-2.255C11.479 2.132 8 5.86 8 10.314V14H6.5c-.827 0-1.5.673-1.5 1.5v13c0 .827.673 1.5 1.5 1.5h19c.827 0 1.5-.673 1.5-1.5v-1c0-.827-.673-1.5-1.5-1.5h-2a.5.5 0 0 0 0 1h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-19a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 .5-.5h2a.5.5 0 0 0 .5-.5v-4.186c0-3.919 3.043-7.199 6.785-7.311a6.923 6.923 0 0 1 5.09 1.973A6.946 6.946 0 0 1 23 10v4.5a.498.498 0 0 0 .5.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4c-.827 0-1.5.673-1.5 1.5v8a.5.5 0 0 0 1 0v-8a.5.5 0 0 1 .5-.5h4c.827 0 1.5-.673 1.5-1.5v-1c0-.827-.673-1.5-1.5-1.5z"
    />
    <path
      className="open_een"
      d="M21 14.5V10c0-2.757-2.243-5-5-5s-5 2.243-5 5v4.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5zm-1-.5h-8v-4c0-2.206 1.794-4 4-4s4 1.794 4 4v4z"
    />
  </svg>
);
export default SVGComponent;
