import React, { useState, useEffect } from 'react';
import Ndropdown from './Ndropdown';
import { Networkswitch } from 'data/networkswitch';
import { useEthers } from '@usedapp/core';
import { BSC, Base, ShardeumChain, ZetaChain } from 'config/constants/testchain';
import { useDefaultChainId } from 'config/useDefaultChainId';

const MenuButton = () => {
    const chainId = useDefaultChainId();
    const { switchNetwork, account, activate, activateBrowserWallet } = useEthers();
    const [itemData, setItemData] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [activeItem, setActiveItem] = useState({
      img: '/images/cards/zeta.png',
      title: 'ZETA',
    });

    useEffect(() => {
        if (chainId === ZetaChain.chainId) {
            setActiveItem({
                img: '/images/cards/zeta.png',
                title: 'ZETA',
            });
        } else if (chainId === ShardeumChain.chainId) {
            setActiveItem({
                img: '/images/cards/shm.svg',
                title: 'SHARDEUM',
            });
        } else if (chainId === Base.chainId) {
            setActiveItem({
                img: '/images/cards/Base.png',
                title: 'BASE',
            });
        }
    }, [chainId]);

    useEffect(() => {
      // localStorage.removeItem('network-switch');
        const nSwitch = window.localStorage.getItem('network-switch');
        if (nSwitch) {
            const tm = JSON.parse(nSwitch);
            if (Array.isArray(tm)) {
                setItemData(tm);
                tm.forEach((elm) => {
                    if (elm.isActive) {
                        setActiveItem({
                            img: elm.iconimg,
                            title: elm.title,
                        });
                    }
                });
            } else {
                console.error('Unexpected format in localStorage');
            }
        } else {
            setItemData(Networkswitch);
        }
    }, []);

    const toggleNetworkDropdown = () => {
        setToggle((prev) => !prev);
    };

    const updateMenuItem = async (index) => {
        const selectedNetwork = itemData[index];
        const networkTitle = selectedNetwork.title;

        switch (networkTitle) {
            case 'ZETA':
                await switchNetwork(ZetaChain.chainId);
                break;
            case 'SHARDEUM':
                await switchNetwork(ShardeumChain.chainId);
                break;
            case 'BASE':
                await switchNetwork(Base.chainId);
                break;
            default:
                console.error('Unknown network title:', networkTitle);
                return;
        }

        const updatedItemData = itemData.map((item, i) => ({
            ...item,
            isActive: i === index,
            status: i === index,
            subtitle: i === index ? 'Connected' : 'Not Connected',
        }));

        setItemData(updatedItemData);

        setActiveItem({
            img: selectedNetwork.iconimg,
            title: networkTitle,
        });

        setToggle(false);

        localStorage.setItem('network-switch', JSON.stringify(updatedItemData));
        window.dispatchEvent(new Event('storage'));
    };

    return (
        <div className='relative'>
            <div className='menu-items-div items-center dark:bg-dark-1 dark:text-white' onClick={toggleNetworkDropdown}>
                <div className='menu-item-left items-center'>
                    <span className='item-icon'>
                        <img width='26' src={activeItem.img} alt='' />
                    </span>
                </div>
                <div className='flex p-1 rounded-xl bg-white justify-center items-center'>
                    <img style={!toggle ? { transform: 'rotate(180deg)' } : {}} src='/images/headericons/arrow-up.svg' alt='' />
                </div>
            </div>
            {toggle && <Ndropdown updateMenuItem={updateMenuItem} itemData={itemData} />}
        </div>
    );
};

export default MenuButton;
