import * as React from "react";
const SVGComponent = (props) => (
  <svg
  width={20}
  height={20}
    viewBox="-4.06 0 48.118 48.118"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g data-name={14} fill="#59bdff">
      <path
        data-name="Path 180"
        d="M276.113 458.064c.409-1.311.383-3.371-2.062-5.738a6.14 6.14 0 0 0-4.263-1.978c-4.359 0-7.406 5.965-8.964 9.98-1.558-4.015-4.605-9.98-8.964-9.98a6.142 6.142 0 0 0-4.263 1.978c-2.445 2.367-2.471 4.427-2.062 5.738 1.6 5.137 12.592 7.209 14.779 7.572a1.541 1.541 0 0 0 .243.02 1.286 1.286 0 0 1 .534 0 1.552 1.552 0 0 0 .243-.02c2.187-.363 13.177-2.436 14.779-7.572Zm-27.743-.884c-.233-.749.2-1.664 1.293-2.721a3.341 3.341 0 0 1 2.2-1.141c2.381 0 4.965 4.642 6.507 8.9-4.359-1.024-9.342-2.93-10-5.038Zm24.908 0c-.655 2.1-5.633 4.009-9.988 5.033 1.411-3.852 3.959-8.894 6.5-8.894a3.341 3.341 0 0 1 2.2 1.141c1.086 1.056 1.521 1.971 1.288 2.719Z"
        transform="translate(-241 -450.348)"
      />
      <path
        data-name="Rectangle 59"
        d="M241 467.466h18v9h-18z"
        transform="translate(-241 -450.348)"
      />
      <path
        data-name="Rectangle 60"
        d="M263 467.466h18v9h-18z"
        transform="translate(-241 -450.348)"
      />
      <path
        data-name="Rectangle 61"
        d="M243 478.466h16v20h-16z"
        transform="translate(-241 -450.348)"
      />
      <path
        data-name="Rectangle 62"
        d="M263 478.466h16v20h-16z"
        transform="translate(-241 -450.348)"
      />
    </g>
  </svg>
);
export default SVGComponent;
