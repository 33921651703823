import React from 'react'
import Input from './Input'

export default function PresalePrivate({setFirstRelease}) {
    return (
        <div>
            <div className="flex items-center gap-5 mt-5">
                <Input heading={"First Release on sale"} changeState={setFirstRelease} />
            </div>
        </div>
    )
}
