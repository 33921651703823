import React from "react";
import { Link } from "react-router-dom";
import Timer from "./Subcomponents/Timer";
import { useEffect, useState } from "react";
import PreviewDetails from "components/Common/PreviewDetails";
import getSaleInfo from "utils/getSaleInfo";

import PercentFilled from "./Subcomponents/PercentFilled";
import FairPercentFilled from "./Subcomponents/FairPercentFilled";

export default function PoolsBase({
  pools,
  pool,
  address,
  soft_cap,
  status,
  loading,
  icon, finished,
  isCancelled,
  saleAddress,
}) {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-8">
      {!loading &&
        pools?.map((pool) => (
          <Link to={`/launchpad/pools/${pool._id}`} key={pool._id}>
            <div className="flex flex-col relative overflow-hidden">
              <div className="overflow-hidden"></div>
              <div className="box bg-white dark:bg-dark-1 rounded-[20px] py-[-1px] px-[-1px] pb-4 pr-4 pl-4">
              <div className="flex items-center justify-between pt-2 pb-2">
              <div className="col-end-7 col-span-2">
              {pool.sale.saleType === "public" && (
                <span className="text-[12px] font-bold bg-[#faf2cd] py-[3px] px-2 text-black rounded-[5px]">
                  🔖&nbsp;PublicSale
                </span>
              )}
              {pool.sale.saleType === "publicvesting"  && (
                <span className="text-[12px] font-bold bg-[#c5f9fc] py-[3px] px-2 text-black rounded-[5px]">
                  🔖&nbsp;PublicSale&nbsp;+&nbsp;Vesting
                </span>
              )}
              {pool.sale.saleType === "private" && (
                <span className="text-[12px] font-bold bg-[#c5f9fc] py-[3px] px-2 text-black rounded-[5px]">
                  🔖&nbsp;PrivateSale
                </span>
              )}
              {pool.sale.saleType === "fairlaunch" && (
                <span className="text-[12px] font-bold bg-[#fbfcc5] py-[3px] px-2 text-black rounded-[5px]">
                  🔖&nbsp;FairLaunch
                </span>
              )}
              </div>
              <div className="col-end-7 col-span-2">
              {pool.sale.status === "Live" && (
                <span className="ml-4 text-[12px] font-bold bg-[#d4faf9] dark:bg-[#B86363] py-[3px] px-2 text-black rounded-[4px] pulsate">
                  🟢&nbsp;Sale Live
                </span>
              )}
              {pool.sale.status === "Upcoming" && (
                <span className="ml-4 text-[12px] font-bold bg-[#faf7d4] dark:bg-[#faf7d4] py-[3px] px-2 text-#ffae52 rounded-[4px] pulsate">
                🟠&nbsp;Upcoming
                </span>
              )}

              {pool.sale.status === "Ended" && (
                  <span className="ml-4 text-[12px] font-bold bg-[#d7fcf0] dark:bg-[#e72ab0] py-[3px] px-2 text-black rounded-[4px] pulsate">
                    🔴&nbsp;Ended
                  </span>
              )}

              </div>
              </div>
                <div className="flex items-center relative">
                  <img
                    src={pool.sale.image}
                    alt={pool.sale.name}
                    className="w-[54px] h-[54px] relative z-0"
                  />
                  {pool.sale.chainID === 8082 && pool.sale.currency.icon && (
                    <img className="absolute top-8 left-8 w-5 h-5 m-1 z-10" src={pool.sale.currency.icon} alt="Currency Icon" />
                  )}
                  {pool.sale.chainID === 7000 && pool.sale.currency.icon && (
                    <img className="absolute top-8 left-8 w-5 h-5 m-1 z-10" src={pool.sale.currency.icon} alt="Currency Icon" />
                  )}
                  {pool.sale.chainID === 56 && pool.sale.currency.icon && (
                   <img className="absolute top-8 left-8 w-5 h-5 m-1 z-10" src={pool.sale.currency.icon} alt="Currency Icon" />
                  )}
                  {pool.sale.chainID === 8453 && pool.sale.currency.icon && (
                    <img className="absolute top-8 left-8 w-5 h-5 m-1 z-10" src={pool.sale.currency.icon} alt="Currency Icon" />
                  )}

                  <div className="ml-4">
                    <div className="flex">
                     <h3 className=" font-bold dark:text-light-text text-[14px]">
                       {pool.sale.name}
                     </h3>
                  </div>

                    <div className="flex flex-col mt-2">
                      {/* tags are not array, its a string, we have to divide by space*/}
                      <div className="flex flex-wrap">
                      {pool.sale.tags.split(",").map(
                        (tag) =>
                        tag !== "" &&
                        tag !== "Migration" && (
                          <span
                          key={tag}
                          className="text-[10px] font-bold bg-[#F6E05E] dark:bg-[#B86363] py-[2px] px-2 dark:text-white text-black rounded-[10px] mr-2"
                          >
                              {tag}
                            </span>
                          )
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                { (pool.sale.saleType === "private" || pool.sale.saleType === "public" || pool.sale.saleType === "publicvesting" ) && (
                  <div className="flex items-center justify-between mt-8">
                    <span className="text-sm font-medium text-gray dark:text-gray-dark">
                      1 {pool.sale.currency.symbol} = {pool.sale.presalePrice} {pool.sale.token.tokenSymbol}
                    </span>
                  </div>
                )}

                { (pool.sale.saleType === "fairlaunch") && (
                  <div className="flex items-center justify-between mt-8">
                    <span className="text-sm font-medium text-gray dark:text-gray-dark">
                      Max Buy = {pool.sale.maxAllocation} {pool.sale.currency.symbol}
                    </span>
                  </div>
                )}

                <div className="flex items-center justify-between mt-8">
                  <span className="text-sm font-medium text-gray dark:text-gray-dark">
                    Soft Cap
                  </span>
                  <div className="flex items-center">
                    <span className="ml-2 font-bold text-dark-text dark:text-light-text">
                      {pool.sale.softCap} {pool.sale.currency.symbol}
                    </span>
                  </div>
                </div>
                { (pool.sale.saleType === "private" || pool.sale.saleType === "public" || pool.sale.saleType === "publicvesting" ) && (
                <div className="flex items-center justify-between mt-4">
                  <span className="text-sm font-medium text-gray dark:text-gray-dark">
                    Hard Cap
                  </span>
                  <div className="flex items-center">
                    <span className="ml-2 font-bold text-dark-text dark:text-light-text">
                      {pool.sale.hardCap} {pool.sale.currency.symbol}
                    </span>
                  </div>
                </div>
                )}
                { (pool.sale.saleType === "fairlaunch" || pool.sale.saleType === "public" || pool.sale.saleType === "publicvesting" ) && (
                <div className="flex items-center justify-between mt-4">
                  <span className="text-sm font-medium text-gray dark:text-gray-dark">
                    Liquidity
                  </span>
                  <div className="flex items-center">
                    <span className="ml-2 font-bold text-dark-text dark:text-light-text">
                      <span className="text-dark-text dark:text-light-text font-semibold">
                        {pool.sale.amountLiquidity
                          ? pool.sale.amountLiquidity
                          : "---"}
                      </span>{" "}
                      %
                    </span>
                  </div>
                </div>
                )}
                { (pool.sale.saleType === "fairlaunch") && (
                <div className="flex items-center justify-between mt-4">
                  <span className="text-sm font-medium text-gray dark:text-gray-dark">
                    Min Buy
                  </span>
                  <div className="flex items-center">
                    <span className="ml-2 font-bold text-dark-text dark:text-light-text">
                      {pool.sale.minAllocation} {pool.sale.currency.symbol}
                    </span>
                  </div>
                </div>
                )}

                { (pool.sale.saleType === "private" || pool.sale.saleType === "public" || pool.sale.saleType === "publicvesting" ) && (
                <div className="flex items-center justify-between mt-5">
                  <span className="text-xs  text-gray dark:text-gray-dark">
                    Sold:
                    {/* idk where to get filled perccentage */}
                  </span>

                  <span className="text-xs  text-dim-text dark:text-dim-text-dark">
                    {pool.sale.hardCap * (pool.sale.filledPercent / 100)} {pool.sale.currency.symbol}
                  </span>
                </div>
              )}

              {(pool.sale.saleType === "fairlaunch") && (
              <div className="flex items-center justify-between mt-5">
                <span className="text-xs  text-gray dark:text-gray-dark">
                  Sold:
                </span>

                <span className="text-xs  text-dim-text dark:text-dim-text-dark">
                  {pool.sale.softCap * (pool.sale.filledPercent / 100)} {pool.sale.currency.symbol}
                </span>
              </div>
            )}

                <PercentFilled
                address={pool.sale.saleAddress}
                item={pool}
                sale = {pool.sale}
                isCancelled={pool.isCancelled}
                isFinished={pool.isFinished}
                saleType = {pool.sale.saleType}
                />

                <div className="flex items-center justify-between mt-2">
                    <span className="text-xs font-medium text-gray dark:text-gray-dark">
                      Lockup Time
                    </span>
                    {/* Mengonversi timestamp ke format tanggal-bulan-tahun */}
                    <PreviewDetails
                      value={new Date(pool.sale.lockup * 1000).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}
                    />
                </div>
                {pool.sale.status !== "Ended" && (
                  <div className="flex items-center justify-between mt-5">
                    <span className="text-xs font-medium text-gray dark:text-gray-dark">
                      {pool.sale.status === "Live" ? "Ends in" : "Starts in"}
                    </span>
                    <Timer
                      date={
                        pool.sale.status === "Live"
                          ? new Date(pool.sale.endDate * 1000)
                          : new Date(pool.sale.startDate * 1000)
                      }
                    />
                  </div>
                )}


              </div>
            </div>
          </Link>
        ))}
    </div>
  );
}
