import Web3 from "web3";
import PublicAbi from "../config/abi/PublicLaunchpadAbi.json";
import PublicVestingAbi from "../config/abi/PublicVestingAbi.json";
import PrivateAbi from "../config/abi/PrivateLaunchpadAbi.json";
import FairAbi from "../config/abi/FairLaunchpadAbi.json";
import {
  Public_FACTORY,
  Private_FACTORY,
  PublicVesting,
  FairLaunch_FACTORY,
} from "config/constants/LaunchpadAddress";

async function getDeploymentFeePublic(saleType) {
    const web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    const chainId = await web3.eth.getChainId();
    const publicFactory = Public_FACTORY[chainId];
    const publicVestFactory = PublicVesting[chainId];
    const privateFactory = Private_FACTORY[chainId];
    const fairFactory = FairLaunch_FACTORY[chainId];
    let contract = null;
    if (chainId === chainId) {
        contract = new web3.eth.Contract(PublicAbi, publicFactory);
    } else {
        if (saleType === 'public')
        contract = new web3.eth.Contract(PublicAbi, publicFactory);
        else
        contract = new web3.eth.Contract(PublicAbi, privateFactory);
    }
    const fee = await contract.methods.fee().call();
    console.log(fee, "fee")
    return fee;
}

export default getDeploymentFeePublic;
