import PreviewDetails from "components/Common/PreviewDetails";
import React from "react";
import { useEffect, useState } from "react";
import { Contract } from "ethers";
import { useEthers } from "@usedapp/core";
import PublicSaleAbi from "../../../config/abi/PublicSale.json";
import PublicSaleErcAbi from "../../../config/abi/PublicSaleErcAbi.json";
import PrivateSaleAbi from "../../../config/abi/PrivateSale.json";
import PrivateSaleErcAbi from "../../../config/abi/PrivateSaleErcAbi.json";
import FairLaunchAbi from "../../../config/abi/FairlaunchSale.json";
import PublicVestAbi from "../../../config/abi/PublicVesting.json";
import FairLaunchErcAbi from "../../../config/abi/FairlaunchErcAbi.json";
import ERCAbi from "../../../config/abi/ERC20.json";
import { BACKEND_URL } from "config/constants/LaunchpadAddress";
import axios from "axios";
import getSuccessPublic from "utils/successfulPublic";
import getIsFinished from "utils/getFinished";
import { useModal } from "react-simple-modal-provider";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PercentFilled from "../Pools/Subcomponents/PercentFilled";
import getSaleInfo from "utils/getSaleInfo";
import { getLiquidityLockList, getLpLockInfos } from "utils/getLockList";
import { getLpInfo } from "utils/lpInfo";
import { BigNumber } from "ethers";
import { formatBigToNum } from "utils/numberFormat";
import { ROUTER_ADDRESS } from "config/constants/LaunchpadAddress";
import { FACTORY_ADDRESS } from 'config/constants/address'
import { ethers, utils } from "ethers";
import { useDefaultChainId } from "config/useDefaultChainId";
export default function AdminPanel({
  status,
  hard_cap,
  filled_percent,
  soft_cap,
  finished,
  sale,
  objId,
  cancelled,
}) {
  //console.log("Filled Percent:", filled_percent);
  const { library } = useEthers();
  const chainId = useDefaultChainId();
  const [isFinished, setIsFinished] = useState(null);
  const [Raised, setRaised] = useState(0);
  const [bnbFilled, setBnbFilled] = useState(0);
  const [saleInfo, setSaleInfo] = useState(null);
  const [lock, setLock] = useState(null);
  const [finalized, setFinalized] = useState();
  const [contributors, setContributors] = useState(null);
  const [totalTokenSale, setTotalTokenSale] = useState(0);
  const [tokenSale, setTokenSale] = useState(0);
  const [lpPercent, setLpPercent] = useState(0);
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [whiteListedAddresses, setWhiteListedAddresses] = useState([""]);
  //LoadingModal
  const { open: openLoadingModal, close: closeLoadingModal } =
    useModal("LoadingModal");

    const approveTokens = async (library) => {
     const contract = new Contract(
       sale.token.tokenAddress,
       ERCAbi,
       library.getSigner()
     );

     const balance = await contract.balanceOf(sale.saleAddress)

     const amount = ethers.constants.MaxUint256;
     const router = ROUTER_ADDRESS[chainId];

     try {
       const approval = await contract.approve(router, amount);
       await approval.wait();
       return true;
     } catch (error) {
       console.error("Token approval failed.");
       return false;
     }
   };

   useEffect(() => {
     const result = getSaleInfo(sale.saleAddress, sale.saleType).then((res) => {
       setSaleInfo(res);
       if (res && res.totalBNBRaised !== undefined && sale.softCap !== undefined && sale.softCap !== 0) {
         const bnbFilled = (res.totalBNBRaised).toString();
         setBnbFilled(bnbFilled);
         const amountToGet = bnbFilled * sale.presalePrice;
         console.log("amountToGet:", amountToGet)
       }
     });
   }, []);

  const getContributors = async () => {
    try {
      let abi;
      if (sale.saleType === "public") {
        abi = PublicSaleAbi;
      } else if (sale.saleType === "publicvesting" ) {
        abi = PublicVestAbi;
    } else if (sale.saleType === "private") {
      abi = PrivateSaleAbi;
  } else if (sale.saleType === "fairlaunch") {
      abi = FairLaunchAbi;
    }
      const contract = new Contract(
        sale.saleAddress,
        abi,
        library.getSigner()
      );
      const contributors = await contract.numberOfParticipants();
      setContributors(contributors.toNumber());
    } catch (err) {
      console.log(err,"ahahahah");
    }
  };

  const getTokenLiquidity = async () => {
    try {
      let abi;
      if (sale.saleType === 'fairlaunch') {
        abi = FairLaunchAbi;
      }
      const contract = new Contract(sale.saleAddress, abi, library.getSigner());
      const totalTokenSaleValue = await contract.getliquidityEth();
      const lpPercentValue = await contract.lpPercent();
      const tokenSaleValue = await contract.getBalance();
      setTotalTokenSale(totalTokenSaleValue.toString());
      setLpPercent(lpPercentValue.toString());
      setTokenSale(tokenSaleValue.toString());
      console.log("tokenSale:", totalTokenSaleValue.toString());
      console.log("lpPercent:", lpPercentValue.toString());
      console.log("tokenBalance:", tokenSaleValue.toString());
    } catch (err) {
      console.error('Error fetching liquidity token:', err);
    }
  };
  console.log(finished, "finished");
  const handleAddressChange = (newValue) => {
    const addressesArray = newValue.split(",");
    const updatedAddresses = addressesArray.map((address) =>
      address.trim().toLowerCase()
    );
    setWhiteListedAddresses(updatedAddresses);
  };
  console.log(whiteListedAddresses, "whiteListedAddresses");
  async function getFinished() {
    const res = await getIsFinished(sale.saleAddress).then((res) => {
      setIsFinished(res);
    });
  }
  async function getCurrentSaleInfo() {
    const res = await getSuccessPublic(sale.saleAddress).then((res) => {
      setSaleInfo(res);
    });
    const lockInfo = await getLpLockInfos(
      ["0x2AbeDd70408f5AF52DAc92fda9361fc9dC99ae30"],
      chainId
    );
    setLock(lockInfo);
  }
  console.log(lock, "lock");

  useEffect(() => {
    getContributors();
    getFinished();
    getCurrentSaleInfo();
  }, []);

  const getLatestLiquidityLock = async (chainId) => {
  try {
    const tokenList = await getLiquidityLockList(chainId);

    if (tokenList.success && tokenList.data.length > 0) {
      // Assuming the tokenList is sorted by date or another criterion
      return tokenList.data[tokenList.data.length - 1];
    }
  } catch (error) {
    console.error('Error fetching latest liquidity lock:', error);
    return null;
  }
};

  const finalizeSale = async () => {
    if (chainId !== sale.chainID) {
      toast.error("Please switch to appropriate network");
      return;
    }
    openLoadingModal();
     // const approvalSuccess = await approveTokens(library);
  // if (!approvalSuccess) {
   // Penanganan jika approval gagal
  //   toast.error("Token approval failed. Sale cannot be finalized.");
    // closeLoadingModal();
    // return;
   // }
    let contract;
    const coinSymbol = sale.currency.symbol;

    if (sale.currency.symbol === coinSymbol) {
      if (sale.saleType === "public") {
        contract = new Contract(
          sale.saleAddress,
          PublicSaleAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "publicvesting" ) {
        contract = new Contract(
          sale.saleAddress,
          PublicVestAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "private") {
        contract = new Contract(
          sale.saleAddress,
          PrivateSaleAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "fairlaunch") {
        contract = new Contract(
          sale.saleAddress,
          FairLaunchAbi,
          library.getSigner()
        );
      }
    } else {
      if (sale.saleType === "public") {
        contract = new Contract(
          sale.saleAddress,
          PublicSaleErcAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "private") {
        contract = new Contract(
          sale.saleAddress,
          PrivateSaleErcAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "fairlaunch") {
        contract = new Contract(
          sale.saleAddress,
          FairLaunchErcAbi,
          library.getSigner()
        );
      }
    }
    console.log(contract);
    try {
        const gasEstimate = await contract.estimateGas.finishSale();
        const tx = await contract.finishSale({ gasLimit: gasEstimate });
        console.log("Transaction successful:", tx);
        await tx.wait();
      try {
        const token = await getLiquidityLockList(chainId);
        console.log(token);
        if (token) {
          //put last token in token array in object
          const lockInfo = await getLpLockInfos(
            [token.data[token.data.length - 1]],
            chainId
          );
          console.log(lockInfo, "lockInfo");
          const tokenInfo = await getLpInfo(lockInfo.data[0].info.token);
          const lockObject = {
            address: token.data[token.data.length - 1],
            first: lockInfo.data[0].info[1],
            second: lockInfo.data[0].info[2],
            third: lockInfo.data[0].info[3],
            fourth: lockInfo.data[0].info[4],
            fifth: lockInfo.data[0].info[5],
            sixth: lockInfo.data[0].info[6],
            amount: lockInfo.data[0].info.amount,
            isVesting: lockInfo.data[0].info.isVesting,
            isWithdrawn: lockInfo.data[0].info.isWithdrawn,
            lockDate: lockInfo.data[0].info.lockDate,
            logoImage: lockInfo.data[0].info.logoImage,
            token: tokenInfo,
            unlockDate: lockInfo.data[0].info.unlockDate,
            owner: lockInfo.data[0].owner,
            tokenAddress: lockInfo.data[0].token,
          };

          await axios.post(`${BACKEND_URL}/api/lock`, {
            Lock: lockObject,
            liquidity: true,
            chainId: chainId,
          });
        }
      } catch (err) {
        console.log(err);
      }
      toast.success("Sale Finalized Successfully");
    } catch (err) {
      alert("Something went wrong");
      closeLoadingModal();
      console.error("Transaction failed:", err);
      return;
    }
    const saleInfo = await getSaleInfo(
      sale.saleAddress,
      sale.saleType,
      sale.currency.symbol
    );
    console.log('saleInfo:', saleInfo)
    let perc;
    if (sale.saleType === "public" || sale.saleType === "publicvesting"  || sale.saleType === "private" || sale.saleType === "fairlaunch" || sale.currency.symbol === coinSymbol) {
      perc = await saleInfo.totalBNBRaised;
    } else {
      perc = await saleInfo.totalERC20Raised;
    }
    let percents;
    if (sale.saleType === "fairlaunch") {
      perc = BigNumber.from(perc); // Mengubah variabel perc yang sudah dideklarasikan sebelumnya
      percents = perc.mul(100).div(saleInfo.softCap);
      console.log("percents:", percents);
    } else if (sale.saleType === "public" || sale.saleType === "private" || sale.saleType === "publicvesting") {
      perc = BigNumber.from(perc); // Mengubah variabel perc yang sudah dideklarasikan sebelumnya
      percents = perc.mul(100).div(saleInfo.hardCap);
    }

    const filled = formatBigToNum(percents.toString(), 0, 1);
    //update the isFinised in database
      const finalSaleObject = {
        saleId: sale.saleId,
        saleAddress: sale.saleAddress,
        saleType: sale.saleType,
        github: sale.github,
        website: sale.website,
        twitter: sale.twitter,
        whitepaper: sale.whitepaper,
        linkedin: sale.linkedin,
        discord: sale.discord,
        telegram: sale.telegram,
        kyc: sale.kyc,
        audit: sale.audit,
        safu: sale.safu,
        youtube: sale.youtube,
        youtube: sale.youtube,
        image: sale.image,
        name: sale.name,
        description: sale.description,
        tags: sale.tags,
        tags2: sale.tags2,
        token: sale.token,
        firstRelease: sale.firstRelease,
        percent1: sale.percent1,
        percent2: sale.percent2,
        percent3: sale.percent3,
        percent4: sale.percent4,
        tgeRelaseDate: sale.tgeRelaseDate,
        releaseDate1: sale.releaseDate1,
        releaseDate2: sale.releaseDate2,
        releaseDate3: sale.releaseDate3,
        releaseDate4: sale.releaseDate4,
        minAllocation: sale.minAllocation,
        maxAllocation: sale.maxAllocation,
        amountLiquidity: sale.amountLiquidity,
        minAllocation: sale.minAllocation,
        maxAllocation: sale.maxAllocation,
        amountLiquidity: sale.amountLiquidity,
        listing: sale.listing,
        lockup: sale.lockup,
        presalePrice: sale.presalePrice,
        fairAmount: sale.fairAmount,
        endDate: Math.floor(Date.now() / 1000),
        startDate: sale.startDate,
        hardCap: sale.hardCap,
        softCap: sale.softCap,
        unsoldToken: sale.unsoldToken,
        currency: sale.currency,
        dex: sale.dex,
        whiteisting: sale.whiteisting,
        whiteListedAddresses: sale.whiteListedAddresses,
        owner: sale.owner,
        isFinished: sale.isFinished,
        chainID: sale.chainID,
        filledPercent: filled,
      }
    try {
      const res = await axios.put(`${BACKEND_URL}/api/sale/${objId}`, {
        isFinished: "true",
        sale: finalSaleObject,
      });
      toast.success("Sale Finalized Successfully");
      // window.location.reload();
    } catch (error) {
    console.error("Transaction failed:", error);
    // Log detailed error information
    if (error.data && error.data.message) {
        console.error("Error message:", error.data.message);
    }
      closeLoadingModal();
    }
    setBnbFilled(bnbFilled);
    closeLoadingModal();
  };

  const cancelSale = async () => {
    if (chainId !== sale.chainID) {
      toast.error("Please switch to appropriate network");
      return;
    }
    openLoadingModal();
    let contract;
    const coinSymbol = sale.currency.symbol;

    if (sale.currency.symbol === coinSymbol) {
      if (sale.saleType === "public") {
        contract = new Contract(
          sale.saleAddress,
          PublicSaleAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "publicvesting" ) {
        contract = new Contract(
          sale.saleAddress,
          PublicVestAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "private") {
        contract = new Contract(
          sale.saleAddress,
          PrivateSaleAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "fairlaunch") {
        contract = new Contract(
          sale.saleAddress,
          FairLaunchAbi,
          library.getSigner()
        );
      }
    } else {
      if (sale.saleType === "public") {
        contract = new Contract(
          sale.saleAddress,
          PublicSaleErcAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "private") {
        contract = new Contract(
          sale.saleAddress,
          PrivateSaleErcAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "fairlaunch") {
        contract = new Contract(
          sale.saleAddress,
          FairLaunchErcAbi,
          library.getSigner()
        );
      }
    }
    console.log(contract);
    try {
      console.log(status);
      const tx = await contract.cancelSale();
      await tx.wait();
      toast.success("Sale Cancelled Successfully");
    } catch (err) {
      alert("Something went wrong");
      closeLoadingModal();
      console.log(err);
      return;
    }

    //update the isFinised in database
    try {
      const res = await axios.put(`${BACKEND_URL}/api/sale/${objId}`, {
        isCancelled: "true",
      });

      toast.success("Sale Cancelled Successfully");
      window.location.reload();
    } catch (err) {
      console.log(err);
      closeLoadingModal();
    }
    closeLoadingModal();
  };
  function handleInput() {
    setIsInputOpen(!isInputOpen);
  }

  const disableWhitelist = async () => {
    openLoadingModal();
    let contract;
    const coinSymbol = sale.currency.symbol;
    if (sale.currency.symbol === coinSymbol) {
      if (sale.saleType === "public") {
        contract = new Contract(
          sale.saleAddress,
          PublicSaleAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "publicvesting" ) {
        contract = new Contract(
          sale.saleAddress,
          PublicVestAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "private") {
        contract = new Contract(
          sale.saleAddress,
          PrivateSaleAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "fairlaunch") {
        contract = new Contract(
          sale.saleAddress,
          FairLaunchAbi,
          library.getSigner()
        );
      }
    } else {
      if (sale.saleType === "public") {
        contract = new Contract(
          sale.saleAddress,
          PublicSaleErcAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "private") {
        contract = new Contract(
          sale.saleAddress,
          PrivateSaleErcAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "fairlaunch") {
        contract = new Contract(
          sale.saleAddress,
          FairLaunchErcAbi,
          library.getSigner()
        );
      }
    }
    try {
      const tx = await contract.setWLEnabled(false);
      await tx.wait();

      const saleInfo = await getSaleInfo(
        sale.saleAddress,
        sale.saleType,
        sale.currency.symbol
      );
      let perc;
      if (sale.saleType === "public" || sale.saleType === "publicvesting"  || sale.saleType === "private" || sale.currency.symbol === coinSymbol) {
        perc = await saleInfo.totalBNBRaised;
      } else if (sale.saleType === "fairlaunch") {
        perc = await saleInfo.totalBNBRaised;
      } else {
        perc = await saleInfo.totalERC20Raised;
      }
      perc = BigNumber.from(perc);
      const percents = perc.mul(100).div(saleInfo.hardCap);
      const filled = formatBigToNum(percents.toString(), 0, 1);
      const finalSaleObject = {
        saleId: sale.saleId,
        saleAddress: sale.saleAddress,
        saleType: sale.saleType,
        github: sale.github,
        website: sale.website,
        twitter: sale.twitter,
        whitepaper: sale.whitepaper,
        linkedin: sale.linkedin,
        discord: sale.discord,
        telegram: sale.telegram,
        kyc: sale.kyc,
        audit: sale.audit,
        safu: sale.safu,
        youtube: sale.youtube,
        youtube: sale.youtube,
        image: sale.image,
        name: sale.name,
        description: sale.description,
        tags: sale.tags,
        tags2: sale.tags2,
        token: sale.token,
        firstRelease: sale.firstRelease,
        percent1: sale.percent1,
        percent2: sale.percent2,
        percent3: sale.percent3,
        percent4: sale.percent4,
        tgeRelaseDate: sale.tgeRelaseDate,
        releaseDate1: sale.releaseDate1,
        releaseDate2: sale.releaseDate2,
        releaseDate3: sale.releaseDate3,
        releaseDate4: sale.releaseDate4,
        minAllocation: sale.minAllocation,
        maxAllocation: sale.maxAllocation,
        amountLiquidity: sale.amountLiquidity,
        minAllocation: sale.minAllocation,
        maxAllocation: sale.maxAllocation,
        amountLiquidity: sale.amountLiquidity,
        listing: sale.listing,
        lockup: sale.lockup,
        presalePrice: sale.presalePrice,
        fairAmount: sale.fairAmount,
        endDate:sale.endDate,
        startDate: sale.startDate,
        hardCap: sale.hardCap,
        softCap: sale.softCap,
        unsoldToken: sale.unsoldToken,
        currency: sale.currency,
        dex: sale.dex,
        whiteisting: false,
        whiteListedAddresses: sale.whiteListedAddresses,
        owner: sale.owner,
        isFinished: sale.isFinished,
        chainID: sale.chainID,
        filledPercent: filled,
      }
      try {
        const res = await axios.put(`${BACKEND_URL}/api/sale/${objId}`, {
          sale: finalSaleObject,
        });
        closeLoadingModal();
        window.location.reload();
      } catch (err) {
        closeLoadingModal();
        console.log(err);
      }
    } catch (e) {
      closeLoadingModal();
      console.log(e);
    }
  };

  async function handleAddAddress() {
    if (whiteListedAddresses[0] === "") {
      toast.error("Please enter atleast one address");
      return;
    }
    openLoadingModal();

    try {
      const contract = new Contract(
        sale.saleAddress,
        PublicSaleAbi,
        library.getSigner()
      );

      const tx = await contract.setMultiplyAddressesWL(
        whiteListedAddresses.map((address) => address),
        true
      );
      await tx.wait();
      try {
        //an array with new addresses added after sale.whiteListedAddresses
        const updatedAddresses = [
          ...sale.whiteListedAddresses,
          ...whiteListedAddresses,
        ];
        const saleInfo = await getSaleInfo(
          sale.saleAddress,
          sale.saleType,
          sale.currency.symbol
        );
        let perc;
        const coinSymbol = sale.currency.symbol;
        if (sale.saleType === "public" || sale.saleType === "publicvesting"  || sale.saleType === "private" || sale.currency.symbol === coinSymbol) {
          perc = await saleInfo.totalBNBRaised;
        } else {
          perc = await saleInfo.totalERC20Raised;
        }
        perc = BigNumber.from(perc);
        const percents = perc.mul(100).div(saleInfo.hardCap);
        const filled = formatBigToNum(percents.toString(), 0, 1);
        const finalSaleObject = {
            saleId: sale.saleId,
            saleAddress: sale.saleAddress,
            saleType: sale.saleType,
            github: sale.github,
            website: sale.website,
            twitter: sale.twitter,
            whitepaper: sale.whitepaper,
            linkedin: sale.linkedin,
            discord: sale.discord,
            telegram: sale.telegram,
            kyc: sale.kyc,
            audit: sale.audit,
            safu: sale.safu,
            youtube: sale.youtube,
            youtube: sale.youtube,
            image: sale.image,
            name: sale.name,
            description: sale.description,
            tags: sale.tags,
            tags2: sale.tags2,
            token: sale.token,
            firstRelease: sale.firstRelease,
            percent1: sale.percent1,
            percent2: sale.percent2,
            percent3: sale.percent3,
            percent4: sale.percent4,
            tgeRelaseDate: sale.tgeRelaseDate,
            releaseDate1: sale.releaseDate1,
            releaseDate2: sale.releaseDate2,
            releaseDate3: sale.releaseDate3,
            releaseDate4: sale.releaseDate4,
            minAllocation: sale.minAllocation,
            maxAllocation: sale.maxAllocation,
            amountLiquidity: sale.amountLiquidity,
            minAllocation: sale.minAllocation,
            maxAllocation: sale.maxAllocation,
            amountLiquidity: sale.amountLiquidity,
            listing: sale.listing,
            lockup: sale.lockup,
            presalePrice: sale.presalePrice,
            fairAmount: sale.fairAmount,
            endDate:sale.endDate,
            startDate: sale.startDate,
            hardCap: sale.hardCap,
            softCap: sale.softCap,
            unsoldToken: sale.unsoldToken,
            currency: sale.currency,
            dex: sale.dex,
            whiteisting: sale.whiteisting,
            whiteListedAddresses: sale.whiteListedAddresses,
            owner: sale.owner,
            isFinished: sale.isFinished,
            chainID: sale.chainID,
            filledPercent: filled,
          };
        const res = await axios.put(`${BACKEND_URL}/api/sale/${objId}`, {
          sale: finalSaleObject,
        });
        toast.success("Address Added Successfully");
        closeLoadingModal();
        const canFinalized = saleInfo.totalBNBRaised > soft_cap;
        setFinalized(canFinalized)
        // window.location.reload();
      } catch (err) {
        console.log(err);
        closeLoadingModal();
        toast.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      closeLoadingModal();
      toast.error("Something went wrong");
    }
  }

  return (
    <>
      <div className="hidden md:block px-9 pb-9 bg-white dark:bg-dark-1 rounded-[20px]">
        <div className="w-full flex justify-center">
          <div className="w-1/2 py-5 flex justify-center items-center border-b-2 border-primary-green ">
            <span className="font-bold text-primary-green">Admin Panel</span>
          </div>
        </div>

        <div className="w-full flex justify-between mt-7">


          {status !== "Upcoming" ? (
            <div className="bg-primary-green bg-opacity-[0.08] px-3 py-[0.5px] rounded-[10px] border-[0.5px] border-dashed border-primary-green">
              <span className="rounded-[10px] text-primary-green">
                {status}
              </span>
            </div>
          ) : (
            <div className="bg-[#C89211] bg-opacity-[0.08] px-3 py-[0.5px] rounded-[10px] border-[0.5px] border-dashed border-[#C89211]">
              <span className="rounded-[10px] text-[#C89211]">Upcoming</span>
            </div>
          )}
        </div>

        {(sale.saleType === "private" || sale.saleType === "publicvesting"  || sale.saleType === "public") && (
          <div className="w-full flex mt-3">
            <span className="font-bold text-dark-text dark:text-light-text text-[10]">
              Caps:&nbsp;&nbsp;{soft_cap} - {hard_cap} {sale.currency.symbol}
            </span>
          </div>
        )}

        {sale.saleType === "fairlaunch" && (
          <div className="w-full flex mt-3">
            <span className="font-bold text-dark-text dark:text-light-text text-[10]">
              SoftCap:&nbsp;&nbsp;{soft_cap} {sale.currency.symbol}
            </span>
          </div>
        )}

        {status !== "Upcoming" && (
          <div className="mt-7">
            <div className="flex items-center justify-between">
              {(sale.saleType === "public" || sale.saleType === "publicvesting"  || sale.saleType === "private") && hard_cap && filled_percent && (
                <span className="text-xs  text-gray dark:text-gray-dark">
                  {(hard_cap * (filled_percent / 100)).toLocaleString()}{" "}
                  {sale.currency.symbol}
                </span>
              )}

              {sale.saleType === "fairlaunch" && (
                <span className="font-bold text-dark-text dark:text-light-text text-[10]">
                  Raised: {bnbFilled / 10**18}&nbsp;{sale.currency.symbol}{" "}
                </span>
              )}

              {(sale.saleType === "public" || sale.saleType === "publicvesting"  || sale.saleType === "private") && (
              <span className="text-xs  text-dim-text dark:text-dim-text-dark">
                {hard_cap} {sale.currency.symbol}
              </span>
              )}
            </div>

            {(sale.saleType === "public" || sale.saleType === "publicvesting"  || sale.saleType === "private") && (
            <PercentFilled
              sale = {sale}
              address={sale.saleAddress}
              isFinished={finished}
              isCancelled={cancelled}
              saleType={sale.saleType}
            />
            )}
          </div>
        )}
        {sale.whiteisting !== false && sale.whiteListedAddresses && sale.whiteListedAddresses.map((address, index) => {
            return (
              <div className="mt-7" key={index}>
                <PreviewDetails
                  name={"Whitelisted Address"}
                  value={address}
                  enable_copy={true}
                  address={true}
                  setFunction={handleInput}
                  isInputOpen={isInputOpen}
                />
              </div>
            );
          })}

        {isInputOpen && (
          <div className="mt-7 flex">
            <input
              type="text"
              value={whiteListedAddresses}
              onChange={(e) => handleAddressChange(e.target.value)}
              className="px-3 py-2 rounded-md border dark:border-white border-black text-sm w-full mr-2 dark:text-white text-black"
              placeholder="0xaEa5..."
            />
            <button
              className=" bg-primary-green text-white px-3 py-2 rounded-md focus:outline-none "
              onClick={handleAddAddress}
            >
              Add
            </button>
          </div>
        )}

        {status !== "Upcoming" && contributors != null && (
          <div className="mt-7">
            <PreviewDetails name={"Contributors"} value={contributors} />
          </div>
        )}
        {saleInfo === false && !finished && status !== "Live" && !cancelled && (
          <div className="mt-7">
            <button
              onClick={() => {
                finalizeSale();
              }}
              className={`w-full ${
                status === "Upcoming"
                  ? "bg-light dark:bg-dark text-dark-text dark:text-light-text"
                  : "bg-primary-green text-white"
              } rounded-md font-bold py-4`}
              disabled={status === "Upcoming" ? true : false}
              disabled={finalizeSale || !isFinished ? false : true}
            >
              {/* if sale is not finished then show manage address too */}
              {status === "Upcoming" ? "Manage WhiteList" : "Finalize Sale"}
            </button>
          </div>
        )}
        {
          sale.whiteisting && (
            <div className="mt-7">
              <button
                onClick={() => {
                  disableWhitelist();
                }}
                className={`w-full bg-primary-green text-white rounded-md font-bold py-4`}
              >
                Disable Whitelist
              </button>
            </div>
          )
        }
        {saleInfo === false && !cancelled && (
          <div className="mt-7">
            <button
              onClick={() => {
                cancelSale();
              }}
              className={`w-full ${
                status === "Upcoming"
                ? "bg-light dark:bg-dark text-dark-text dark:text-light-text"
                : "bg-primary-green text-white"
            } rounded-md font-bold py-4`}
            >
              Cancel Sale
            </button>
          </div>
        )}
        {cancelled && (
          <span className="text-sm font-medium text-gray dark:text-gray-dark">
            sale was cancelled{" "}
          </span>
        )}
      </div>
    </>
  );
}
