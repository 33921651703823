// import { BSC } from '@usedapp/core'
// import { BscTestnet } from './chain'
import { BSC, Base, ShardeumChain, ZetaChain } from './testchain'

export const MULTICALL_ADDRESS = {
  [BSC.chainId]: BSC.multicallAddress,
  [ZetaChain.chainId]:  ZetaChain.multicallAddress,
  [ShardeumChain.chainId]: "0x9B58Df5aEd25a0F6c3413402c00ce00C5E506233",
  [Base.chainId]: "0xF6b74a6ca157e990B55B822558CBE5D3147BE508",
}

export const RPC_ADDRESS = {
  [ZetaChain.chainId]: 'https://zetachain-mainnet.public.blastapi.io',
  [ShardeumChain.chainId]: 'https://sphinx.shardeum.org',
  [BSC.chainId]: 'https://bsc-dataseed1.ninicoin.io/',
  [Base.chainId]: 'https://base.llamarpc.com',// 'https://base.llamarpc.com',
}

export const ADMIN_ADDRESS = {
  [BSC.chainId]: BSC.multicallAddress,
  [ZetaChain.chainId]: '0x1bf08cf44A856fe437Bedc89BA53bF43FE5Ca15D',
  [ShardeumChain.chainId]: '0xbC191d3a91903997661d6572fC3ABE686613A395',
  [Base.chainId]: "",
}

export const FACTORY_ADDRESS = {
  [BSC.chainId]: "0x47D166313616c23616c8Dd88c3339A350471D2bb",
  [ZetaChain.chainId]: '0x7057251311B87F51aBF381dfEbd18293d6F63565',
  [ShardeumChain.chainId]: '0xae1C1C2703C9F145298BBFee37a8Cfe3F0B8a558',
  [Base.chainId]: "0x289805470b28062134397d2101a49e816B65A400",
}

export const AIRDROP_FACTORY_ADDRESS = {
  [BSC.chainId]: BSC.multicallAddress,
  [ZetaChain.chainId]: '0xCB33f39bB655019d3abdBF5489A4DABD00759943',
  [Base.chainId]: "",
}
