import React from "react";
import Web3 from "web3";
import PublicSaleAbi from "../../../config/abi/PublicSale.json";
import PublicSaleErcAbi from "../../../config/abi/PublicSaleErcAbi.json";
import FairLaunchAbi from "../../../config/abi/FairlaunchSale.json";
import FairLaunchErcAbi from "../../../config/abi/FairlaunchErcAbi.json";
import PrivateSaleAbi from "../../../config/abi/PrivateSale.json";
import PrivateSaleErcAbi from "../../../config/abi/PrivateSaleErcAbi.json";
import PublicVestAbi from "../../../config/abi/PublicVesting.json";
import { useState, useEffect } from "react";
import getSaleInfo from "utils/getSaleInfo";
import { BigNumber } from "ethers";
import { formatBigToNum } from "utils/numberFormat";
import { useModal } from "react-simple-modal-provider";
import { toast } from "react-toastify";
import { useEthers } from "@usedapp/core";
import { useDefaultChainId } from "config/useDefaultChainId";
import { Contract } from "ethers";
import Timer from "./subComponents/Timer";

export default function LPStatus({ icon, pool, status, sale, isFinished }) {
  const { account, library } = useEthers();
  const chainId = useDefaultChainId();
  const [saleInfo, setSaleInfo] = useState(null);
  const [isUnlockDisabled, setIsUnlockDisabled] = useState(false);
  const [Raised, setRaised] = useState(0);
  const [earningsWithdrawn, setEarningsWithdrawn] = useState(false);
  const { open: openLoadingModal, close: closeLoadingModal } =
    useModal("LoadingModal");
  async function getWithdrawn() {
    try {
      const coinSymbol = sale.currency.symbol;
      const saleInfo = await getSaleInfo(sale.saleAddress,sale.saleType, coinSymbol);
      setEarningsWithdrawn(saleInfo.earningsWithdrawn);
      console.log(saleInfo.earningsWithdrawn, "earningsWithdrawn");
    } catch (err) {
      console.log(err);
    }
  }
  const withdrawEarnings = async () => {
    if (chainId !== sale.chainID) {
      toast.error("Please switch to appropriate network");
      return;
      console.log(withdrawEarnings, "Earnings Withdrawn Status");
    }
    openLoadingModal();
    console.log(sale,"sale")
    let contract;
    const coinSymbol = sale.currency.symbol;
    if (sale.currency.symbol === coinSymbol) {
      if (sale.saleType === "public") {
        contract = new Contract(
          sale.saleAddress,
          PublicSaleAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "publicvesting" ) {
        contract = new Contract(
          sale.saleAddress,
          PublicVestAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "fairlaunch") {
        contract = new Contract(
          sale.saleAddress,
          FairLaunchAbi,
          library.getSigner()
        );
      }
    } else {
      if (sale.saleType === "public") {
        contract = new Contract(
          sale.saleAddress,
          PublicSaleErcAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "fairlaunch") {
        contract = new Contract(
          sale.saleAddress,
          FairLaunchErcAbi,
          library.getSigner()
        );
      }
    }
    console.log(contract, "contract")
    try {
      const currentTime = Math.floor(Date.now() / 1000);
      const unlockTime = await contract.liquidityLockPeriod();
      console.log("Liquidity Unlock Time:", unlockTime.toString());
    } catch (error) {
      console.error("Error fetching liquidity unlock time:", error);
    }

    try {
          const currentTime = Math.floor(Date.now() / 1000);
          if (status === "Ended" && currentTime >= sale.lockup && !isUnlockDisabled) {
            const tx = await contract.withdrawLP();
            await tx.wait();
            toast.success("LP withdrawn successfully");
          } else {
            toast.error("Liquidity not unlocked yet");
          }
          closeLoadingModal();
          window.location.reload();
        } catch (err) {
          console.log(err.message);
          toast.error("Failed to withdraw LP");
          closeLoadingModal();
        }
      };
  useEffect(() => {
    getWithdrawn();
  }, []);


  useEffect(() => {
    const currentTime = new Date().getTime() / 1000;
    const unlockTime = sale.lockup;

    if (currentTime < unlockTime) {
      setIsUnlockDisabled(true);
    } else {
      setIsUnlockDisabled(false);
    }
  }, [sale.lockup]);


  return (
    <div className="hidden md:block p-9 bg-white dark:bg-dark-1 rounded-[20px]">
      <div className="">
      <span className=" flex items-center justify-center font-medium text-sm text-gray dark:text-gray-dark">
      <img src={sale.token.image} alt="pool-icon" className="w-7 h-7" /><img src={icon} alt="pool-icon" className="w-7 h-7 mr-2" />
      </span>
        <span className=" flex items-center justify-center font-medium text-sm text-gray dark:text-gray-dark">
        {sale.token.tokenSymbol}/{sale.currency.symbol} Liquidity Unlock In
        </span>
        <Timer
          className=" flex items-center justify-center font-medium text-sm text-gray dark:text-gray-dark"
          date={new Date(sale.lockup * 1000)}
        />
        <span className=" flex items-center justify-center font-medium text-sm text-gray dark:text-gray-dark">
        LP Locked in presale contract
        </span>
      </div>
      {(sale.lockup && !isUnlockDisabled) && (
      <div className="flex mt-2">
        <button
          className={`w-full rounded-md text-white font-bold py-4 disabled:bg-gray-dark bg-gradient-to-r from-primary-green to-[#C89211]`}
          onClick={withdrawEarnings}
        >
        UNLOCK
        </button>
      </div>
    )}
    </div>
  );
}
