import React from "react";
import Web3 from "web3";
import PublicSaleAbi from "../../../config/abi/PublicSale.json";
import PublicSaleErcAbi from "../../../config/abi/PublicSaleErcAbi.json";
import FairLaunchAbi from "../../../config/abi/FairlaunchSale.json";
import FairLaunchErcAbi from "../../../config/abi/FairlaunchErcAbi.json";
import PrivateSaleAbi from "../../../config/abi/PrivateSale.json";
import PrivateSaleErcAbi from "../../../config/abi/PrivateSaleErcAbi.json";
import PublicVestAbi from "../../../config/abi/PublicVesting.json";
import { useState, useEffect } from "react";
import getSaleInfo from "utils/getSaleInfo";
import { BigNumber } from "ethers";
import { formatBigToNum } from "utils/numberFormat";
import { useModal } from "react-simple-modal-provider";
import { toast } from "react-toastify";
import { useEthers } from "@usedapp/core";
import { useDefaultChainId } from "config/useDefaultChainId";
import { Contract } from "ethers";
import Timer from "./subComponents/Timer";
import { useNativePrice } from './subComponents/useNativePrice'; // Impor useNativePrice

export default function FundRaised({ icon, pool, status, sale, isFinished }) {
  const { account, library } = useEthers();
  const chainId = useDefaultChainId();
  const [saleInfo, setSaleInfo] = useState(null);
  const [Raised, setRaised] = useState(0);
  const [bnbFilled, setBnbFilled] = useState(0);
  const [nativePrice, setNativePrice] = useState();
  const nativePriceData = useNativePrice(chainId);
  console.log('nativePriceData: ', nativePriceData);
  const [earningsWithdrawn, setEarningsWithdrawn] = useState(false);
  const { open: openLoadingModal, close: closeLoadingModal } =
    useModal("LoadingModal");
  async function getWithdrawn() {
    try {
      const coinSymbol = sale.currency.symbol;
      const saleInfo = await getSaleInfo(sale.saleAddress,sale.saleType, coinSymbol);
      setEarningsWithdrawn(saleInfo.earningsWithdrawn);
      console.log(saleInfo.earningsWithdrawn, "earningsWithdrawn");
    } catch (err) {
      console.log(err);
    }
  }
  const withdrawEarnings = async () => {
    if (chainId !== sale.chainID) {
      toast.error("Please switch to appropriate network");
      return;
    }
    if (earningsWithdrawn) {
      toast.error("You Have Already Withdrawn Your Earnings");
      return;
      console.log(earningsWithdrawn, "Earnings Withdrawn Status");
    }
    openLoadingModal();
    console.log(sale,"sale")
    let contract;
    const coinSymbol = sale.currency.symbol;
    if (sale.currency.symbol === coinSymbol) {
      if (sale.saleType === "public") {
        contract = new Contract(
          sale.saleAddress,
          PublicSaleAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "publicvesting" ) {
        contract = new Contract(
          sale.saleAddress,
          PublicVestAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "private") {
        contract = new Contract(
          sale.saleAddress,
          PrivateSaleAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "fairlaunch") {
        contract = new Contract(
          sale.saleAddress,
          FairLaunchAbi,
          library.getSigner()
        );
      }
    } else {
      if (sale.saleType === "public") {
        contract = new Contract(
          sale.saleAddress,
          PublicSaleErcAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "private") {
        contract = new Contract(
          sale.saleAddress,
          PrivateSaleErcAbi,
          library.getSigner()
        );
      } else if (sale.saleType === "fairlaunch") {
        contract = new Contract(
          sale.saleAddress,
          FairLaunchErcAbi,
          library.getSigner()
        );
      }
    }
    console.log(contract, "contract")
    try {
      if (status === "Live") {
        const tx = await contract.withdraw();
        await tx.wait();
        toast.success("Tokens withdrawn successfully");
      } else {
        console.log("Before withdrawal: earningsWithdrawn =", earningsWithdrawn);
        console.log("Before withdrawal: status =", status);
        // const gasEstimate = await contract.estimateGas.withdrawEarnings();
        const tx = await contract.withdrawEarnings({gasLimit: 1000000});
        const receipt = await tx.wait();
        console.log(receipt);
        toast.success("Earnings withdrawn successfully");
      }
      closeLoadingModal();
      window.location.reload();
    } catch (err) {
      console.log(err.message);
      setEarningsWithdrawn(true);
      toast.error("You Have Already Withdrawn Your Earnings");
      closeLoadingModal();
    }
  };

  async function getInfo() {
    const result = await getSaleInfo(pool.saleAddress, pool.saleType, sale.currency.symbol, sale.filledPercent);
    console.log("result:", result)
    setSaleInfo(result);
    const coinSymbol = sale.currency.symbol;
    let tokensRaised;
    if (sale.saleType === "public"|| sale.saleType === "publicvesting" || sale.saleType === "private" || sale.saleType === "fairlaunch"|| sale.currency.symbol === coinSymbol) {
      tokensRaised = BigNumber.from(result.totalBNBRaised);
      console.log(tokensRaised,"tokensRaised")
    }
    else {
      tokensRaised = BigNumber.from(result.totalERC20Raised);
      console.log(tokensRaised,"tokensRaised")
    }
    const percents = (tokensRaised * 100 / result.hardCap);
    const newPercent = formatBigToNum(percents.toString(), 0, 1);
    console.log('Persentase: ', newPercent);
    setRaised(newPercent);
  }

  useEffect(() => {
    getInfo();
    getWithdrawn();
  }, []);

  useEffect(() => {
    const result = getSaleInfo(sale.saleAddress, sale.saleType).then((res) => {
      setSaleInfo(res);
      if (res && res.totalBNBRaised !== undefined && sale.softCap !== undefined && sale.softCap !== 0) {
        const bnbFilled = parseFloat(res.totalBNBRaised);
        setBnbFilled(bnbFilled);
      }
    });
  }, []);

  useEffect(() => {
  let nativePrice; // Declare nativePrice variable outside of if-else blocks

  if (chainId === 8082) {
    nativePrice = nativePriceData?.shardeum?.usd;
  } else if (chainId === 56) {
    nativePrice = nativePriceData?.binancecoin?.usd;
  } else if (chainId === 7000) {
    nativePrice = nativePriceData?.zetachain?.usd;
  } else if (chainId === 8453) {
    nativePrice = nativePriceData?.ethereum?.usd;
  }

  if (typeof nativePrice === 'number') {
    const formattedNativePrice = nativePrice.toFixed(0);
    setNativePrice(formattedNativePrice);
    console.log('nativePrice: ', formattedNativePrice);
  } else {
    console.log('nativePrice is not a valid number:', nativePrice);
  }
}, [chainId, nativePriceData]);

  return (
    <div className="hidden md:block p-9 bg-white dark:bg-dark-1 rounded-[20px]">
      <div className="">
        <span className=" flex items-center justify-center font-medium text-sm text-gray dark:text-gray-dark">
          Fundraised
        </span>
      </div>

      <div className="flex items-center mt-4">
        <img src={icon} alt="pool-icon" className="w-7 h-7 mr-2" />
        <div className="flex items-end">
          {(sale.saleType === "public" || sale.saleType === "publicvesting"  || sale.saleType === "private") && (
            <>
              <span className="font-bold text-dark-text dark:text-light-text text-2xl">
                {parseFloat((sale.hardCap * (Raised / 100)))} {sale.currency.symbol}
              </span>
              {earningsWithdrawn ? (
                <span className="text-gray dark:text-gray-dark ml-2"> (Claimed)</span>
              ) : (
                <span className="text-gray dark:text-gray-dark">
                  &nbsp;($
                  {sale.presalePrice * (sale.hardCap * (Raised / 100)).toFixed(4)})
                </span>
              )}
            </>
          )}
          {sale.saleType === "fairlaunch" && (
            <>
              <span className="font-bold text-dark-text dark:text-light-text text-ms">
                {bnbFilled / 10**18} {sale.currency.symbol}
              </span>
              {earningsWithdrawn ? (
                <span className="text-gray dark:text-gray-dark ml-2"> (Claimed)</span>
              ) : (
                <span className="text-gray dark:text-gray-dark">
                  &nbsp;(${(bnbFilled / 10**18) * nativePrice})
                </span>
              )}
            </>
          )}
        </div>
      </div>

      <div className="flex mt-10">
        <button
          disabled={earningsWithdrawn || !isFinished ? true : false}
          onClick={withdrawEarnings}
          className={`w-full rounded-md text-white font-bold py-4 disabled:bg-gray-dark bg-gradient-to-r from-primary-green to-[#C89211]`}
        >
          {earningsWithdrawn ? "Earnings Claimed" : "Claim"}
        </button>
      </div>
    </div>
  );
}
