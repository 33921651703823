import LaunchpadSVG from 'svgs/Sidebar/launchpad'
import AirplaneSVG from '../svgs/Sidebar/airplane'
import DashboardSVG from '../svgs/Sidebar/dashboard'
import LockerSVG from '../svgs/Sidebar/locker'
import MoreProductsSVG from '../svgs/Sidebar/more_products'
import SheildSecuritySVG from 'svgs/Sidebar/shield_security'
import StakingSVG from 'svgs/Sidebar/staking'
import TradeSVG from 'svgs/Sidebar/trade'
import AirdropSVG from 'svgs/Sidebar/Airdrop'
import PdfSVG from 'svgs/Sidebar/PDF'

export const sitemap = [
  {
    id: 1,
    name: 'Home',
    extendable: false,
    icon: <DashboardSVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" />,
    activeIcon: <DashboardSVG className="fill-primary-green" />,
    subitems: [],
    link: '/',
    sublinks: ['/'],
  },
  {
    id: 2,
    name: 'Launchpad',
    extendable: true,
    icon: <LaunchpadSVG className="fill-dim-text dark:fill-dim-text-dark" />,
    activeIcon: <LaunchpadSVG className="fill-primary-green" />,
    subitems: [
      {
        id: 1,
        name: 'Pools',
        link: '/launchpad/pools',
      },
      {
        id: 2,
        name: 'Create Sale',
        link: '/launchpad/create-sale',
      },
    ],
    sublinks: ['/launchpad/pools', '/launchpad/create-sale'],
  },

 // {
  //   id: 3,
  //   name: 'Locker',
  //   extendable: false,
  //   icon: <LockerSVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" />,
  //   activeIcon: <LockerSVG className="fill-primary-green" />,
  //   subitems: [
      // {
      //   id: 1,
      //   name: 'Locked Assets',
      //   link: '/locked-assets',
      // },
      // {
      //   id: 2,
      //   name: 'Token Locker',
      //   link: '/locker/token-locker',
      // },
      // {
      //   id: 3,
      //   name: 'LP Locker',
      //   link: '/locker/lp-locker',
      // },
    // ],
    // link: '/locked-assets',
    // sublinks: ['/locked-assets'],
  // },
  /* {
    id: 4,
    name: 'Airdropper',
    extendable: true,
    icon: <AirplaneSVG className="fill-dim-text dark:fill-dim-text-dark scale-[1.2] hover:fill-primary-green" style={{paddingLeft: '2px'}} />,
    activeIcon: <AirplaneSVG className="fill-primary-green scale-[1.2]" />,
    subitems: [
      {
        id: 1,
        name: 'Airdrops',
        link: '/airdropper/airdrops',
      },
      {
        id: 2,
        name: 'Create Airdrop',
        link: '/airdropper/create-airdrop',
      },
    ],
    sublinks: ['https://airdrop.arborswap.org/airdropper/airdrops', 'https://airdrop.arborswap.org/airdropper/create-airdrop'],
  }, */
  {
    id: 5,
    name: 'Trade',
    extendable: false,
    icon: <TradeSVG className="fill-dim-text dark:fill-dim-text-dark scale-[1.2] hover:fill-primary-green" style={{paddingLeft: '1.9px', width: '22px'}} />,
    activeIcon: <TradeSVG className="fill-primary-green scale-[1.2]" />,
    subitems: [
      // {
      //   id: 1,
      //   name: 'Exchange',
      //   link: 'https://towerswap.finance/swap',
      // },
      // {
      //   id: 2,
      //   name: 'Liquidity',
      //   link: 'https://towerswap.finance/add',
      // },
      // {
      //   id: 3,
      //   name: 'Limit Orders',
      //   link: 'https://towerswap.finance/liquidity',
      // },
    ],
    link: 'https://towerswap.finance/swap',
    sublinks: [],
  },
  {
    id: 6,
    name: 'Staking',
    extendable: false,
    icon: <StakingSVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" />,
    activeIcon: <StakingSVG className="fill-primary-green" />,
    subitems: [],
    link: 'https://towerswap.finance/pools',
    sublinks: [],
  },
  {
    id: 7,
    name: 'TWS Airdrop',
    extendable: false,
    icon: <AirdropSVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" />,
    activeIcon: <AirdropSVG className="fill-primary-green" />,
    subitems: [],
    link: '/claim-tws',
    sublinks: [],
  },
  /* {
    id: 7,
    name: 'WhitePaper',
    extendable: false,
    icon: <PdfSVG className="fill-dim-text dark:fill-dim-text-dark hover:fill-primary-green" />,
    activeIcon: <PdfSVG className="fill-primary-green" />,
    subitems: [],
    link: '/Docs',
    sublinks: [],
  }, */
]
