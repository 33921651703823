import * as React from "react";
const SVGComponent = (props) => (
  <svg
  width={25}
  height={25}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 496"
    style={{
      enableBackground: "new 0 0 496 496",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        fill: "#3e77bf",
      }}
      d="M247.992 5.163C111.048 5.163 0 116.627 0 254.163c0 109.992 71.048 203.32 169.632 236.24 12.392 2.296 16.912-5.392 16.912-12 0-5.904-.216-21.56-.336-42.344-69 15.064-83.552-33.376-83.552-33.376-11.264-28.768-27.52-36.408-27.52-36.408-22.528-15.456 1.696-15.16 1.696-15.16 24.88 1.752 37.976 25.672 37.976 25.672 22.144 38.048 58.064 27.048 72.192 20.672 2.232-16.08 8.656-27.064 15.736-33.28-55.056-6.296-112.944-27.64-112.944-123.04 0-27.176 9.656-49.408 25.52-66.832-2.568-6.296-11.072-31.6 2.416-65.872 0 0 20.832-6.688 68.2 25.52 19.784-5.52 41-8.28 62.096-8.392 21.048.112 42.28 2.864 62.096 8.392 47.328-32.208 68.128-25.52 68.128-25.52 13.52 34.272 5.016 59.576 2.456 65.872 15.904 17.424 25.504 39.648 25.504 66.832 0 95.64-57.984 116.68-113.232 122.856 8.904 7.688 16.832 22.872 16.832 46.112 0 33.296-.296 60.128-.296 68.296 0 6.672 4.472 14.424 17.048 11.968C425.016 457.387 496 364.123 496 254.163c0-137.536-111.048-249-248.008-249z"
    />
    <path
      style={{
        fill: "#3e77bf",
      }}
      d="M247.992 5.163C111.048 5.163 0 116.627 0 254.163c0 109.992 71.048 203.32 169.632 236.24 12.392 2.296 16.912-5.392 16.912-12 0-5.904-.216-21.56-.336-42.344-69 15.064-83.552-33.376-83.552-33.376-11.264-28.768-27.52-36.408-27.52-36.408-22.528-15.456 1.696-15.16 1.696-15.16 24.88 1.752 37.976 25.672 37.976 25.672 22.144 38.048 58.064 27.048 72.192 20.672 2.232-16.08 8.656-27.064 15.736-33.28-55.056-6.296-112.944-27.64-112.944-123.04 0-27.176 9.656-49.408 25.52-66.832-2.568-6.296-11.072-31.6 2.416-65.872 0 0 20.832-6.688 68.2 25.52 19.784-5.52 41-8.28 62.096-8.392 21.048.112 42.28 2.864 62.096 8.392 47.328-32.208 68.128-25.52 68.128-25.52 13.52 34.272 5.016 59.576 2.456 65.872 15.904 17.424 25.504 39.648 25.504 66.832 0 95.64-57.984 116.68-113.232 122.856 8.904 7.688 16.832 22.872 16.832 46.112 0 33.296-.296 60.128-.296 68.296 0 6.672 4.472 14.424 17.048 11.968C425.016 457.387 496 364.123 496 254.163c0-137.536-111.048-249-248.008-249z"
    />
    <path
      style={{
        fill: "#114c82",
      }}
      d="M378.496 109.131c13.128 33.904 4.752 58.928 2.2 65.176 15.904 17.424 25.504 39.648 25.504 66.832 0 95.64-57.984 116.68-113.232 122.856 8.904 7.688 16.832 22.872 16.832 46.112 0 33.296-.296 60.128-.296 68.296 0 6.672 4.472 14.424 17.048 11.968C425.016 457.387 496 364.123 496 254.163c0-29.56-5.152-57.896-14.568-84.2-34.192-36-72.248-59.976-102.936-60.832zm-185.52 268.192c2.784-5.616 6.184-9.984 9.76-13.144-30.176-3.456-61.168-11.512-83.048-33.744 16.832 28.016 44.24 40.76 73.288 46.888z"
    />
    <path
      style={{
        fill: "#6ca9ea",
      }}
      d="M12.912 270.947c0-137.536 111.048-249 247.992-249 76.04 0 144.056 34.392 189.536 88.488C405.52 46.755 331.616 5.163 247.992 5.163 111.048 5.163 0 116.627 0 254.163c0 61.32 22.12 117.424 58.752 160.792-28.808-40.664-45.84-90.312-45.84-144.008z"
    />
    <path
      style={{
        fill: "#083e66",
      }}
      d="M322.712 426.899c0-23.232-7.928-38.44-16.832-46.128 55.248-6.16 113.24-27.216 113.24-122.848 0-27.184-9.6-49.4-25.52-66.824 2.576-6.296 11.096-31.608-2.456-65.872 0 0-2.576-.792-7.896-.488 5.808 25.84-.416 44.32-2.552 49.568 15.904 17.424 25.504 39.648 25.504 66.832 0 95.64-57.984 116.68-113.232 122.856 8.904 7.688 16.832 22.872 16.832 46.112 0 33.296-.296 60.128-.296 68.296 0 5.872 3.496 12.568 12.92 12.408.064-11.256.288-35.152.288-63.912z"
    />
    <path
      style={{
        fill: "#114c82",
      }}
      d="M117.464 423.995c5.208 10.72 22.712 36.4 68.888 30.992-.064-5.648-.112-11.976-.144-18.928-35.624 7.776-56.656-1.392-68.744-12.064z"
    />
    <path
      style={{
        fill: "#6ca9ea",
      }}
      d="M126.368 391.387a61.241 61.241 0 0 0-8.904-10.544c2.808 4.168 5.792 7.664 8.904 10.544z"
    />
  </svg>
);
export default SVGComponent;
