import * as React from "react";
const DashboardSVG = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
<path fill="#E8EAF6" d="M42 39H6V23L24 6l18 17z"/><path d="m39 21-5-5V9h5zM6 39h36v5H6z" fill="#C5CAE9"/><path fill="#B71C1C" d="M24 4.3 4 22.9l2 2.2L24 8.4l18 16.7 2-2.2z"/><path fill="#D84315" d="M18 28h12v16H18z"/><path fill="#01579B" d="M21 17h6v6h-6z"/><path fill="#FF8A65" d="M27.5 35.5c-.3 0-.5.2-.5.5v2c0 .3.2.5.5.5s.5-.2.5-.5v-2c0-.3-.2-.5-.5-.5z"/></svg>
);
export default DashboardSVG;
